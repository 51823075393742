@import "../../styles/custom";

.esc-calc {
  &__accreditation-radios-container {
    fieldset {
      label {
        flex-grow: 1;
      }
    }
  }

  &__result-list {
    border-top: 0;
  }

  &__result-first-row {
    border-top: 0;
  }

  &__classification-title {
    font-size: 24px;
    font-weight: 600;
  }

  &__result-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
    border-bottom: 1px solid $gray-200;
    font-size: 16px;
    gap: 12px;

    &:last-of-type {
      border-bottom: 0;
    }
  }

  &__result-item-label {
    max-width: 197px;
    white-space: nowrap;
  }

  &__result-item-value {
    font-weight: 600;
    text-align: end;
  }

  &__skill-value-icon {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 4px;
    margin-inline-end: 12px;

    &--high {
      background-color: #0060ff;
    }

    &--medium {
      background-color: #8bae8b;
    }

    &--low {
      background-color: #ddeaa0;
    }

    &--not {
      background-color: #c3c3c3;
    }
  }

  &__submit-btn {
    margin-top: 1.5rem;
  }
}
