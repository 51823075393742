@import "../../styles/custom";

.mobile-banner-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;
  position: relative;
}

@at-root body.arabic {
  @include media-breakpoint-up(lg) {
    .mobile-banner {
      flex-direction: row-reverse;
    }
  }
}

.mobile-banner {
  margin-bottom: 0;
  display: flex;
  flex-direction: column-reverse;
  @include media-breakpoint-up(lg) {
    height: 720px;
    width: 1128px;
    justify-content: flex-end;
    flex-direction: row;
    margin-bottom: 72px;
  }
  &__img {
    position: relative;
    width: calc(100% - 16px);
    left: 0;
    @include media-breakpoint-up(lg) {
      position: absolute;
      z-index: -1;
      width: 100%;
      max-width: 556px;
      padding-right: 0;
      top: 10px;
    }
  }
  &__text-box {
    padding: 0 16px;
    max-width: 100%;

    @include media-breakpoint-up(lg) {
      margin-top: 180px;
      max-width: 488px;
    }

    &__title {
      font-size: 14px;
      font-weight: 700;
      line-height: 150%;
      letter-spacing: 0.6px;
      text-transform: uppercase;
      color: $gray-600;
      margin-bottom: 12px;
    }
    h2 {
      margin-bottom: 15px;
      font-size: 32px;
      font-weight: 600;
      line-height: 130%;
      letter-spacing: -0.2px;
      color: $gray-900;

      @include media-breakpoint-up(lg) {
        font-size: 36px;
      }
    }

    &__article {
      font-size: 16px;
      line-height: 150%;
      color: $gray-700;
      margin-bottom: 40px;
    }

    &__button-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 12px;
      white-space: nowrap;
      margin-bottom: 40px;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
        margin-bottom: 0;
      }
      &__blue-button {
        width: 100%;
        display: flex;
        justify-content: center;
        background: $business-500;
        color: white;
        border: 0;
        border-radius: 4px;
        padding: 12px 24px;
        text-decoration: none;

        &:hover {
          background-color: $business-600;
          color: $white;
        }

        &:active {
          background-color: $business-700;
          color: $white;
        }

        &:focus {
          &:not(:focus-visible) {
            box-shadow: none;
          }

          &:focus-visible {
            @include focus();
          }
        }
      }
      &__green-button {
        width: 100%;
        display: flex;
        justify-content: center;
        background: $individuals-500;
        color: white;
        border: 0;
        border-radius: 4px;
        padding: 12px 24px;
        text-decoration: none;

        &:hover {
          background-color: $individuals-600;
          color: $white;
        }

        &:active {
          background-color: $individuals-900;
          color: $white;
        }

        &:focus {
          &:not(:focus-visible) {
            box-shadow: none;
          }

          &:focus-visible {
            @include focus();
          }
        }
      }
    }
  }
}
