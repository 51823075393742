@import "../../../styles/custom";
.labor-system-materials {
  background-color: $gray-50;
  padding-top: 56px;
  padding-bottom: 56px;
  padding-left: 156px;
  padding-right: 156px;

  div[data-component="Search"] {
    div[data-component="Box"] {
      border: none;
    }
  }

  @include media-breakpoint-down(lg) {
    padding-left: 16px;
    padding-right: 16px;
  }

  &__body {
    margin: 0;
  }

  &__header {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    margin-bottom: 32px;
    @include media-breakpoint-down(lg) {
      font-size: 24px;
    }
  }

  &__tiles-row {
    margin-top: 40px;

    @include media-breakpoint-down(lg) {
      margin-top: 32px;
    }
  }

  &__action-tile {
    @include media-breakpoint-down(lg) {
      padding-top: 35px !important;
    }

    div[data-component="CircleBadge"] {
      div {
        border-color: $gray-800;
      }
    }
  }
}
.labor-system-materials .hero-search-input {
  border-radius: 4px;
}

.labor-system-materials .action-tile-wrapper .action-tile--neutral {
  @include media-breakpoint-down(sm) {
    border: none;
    background-color: $gray-50;
  }
}

.labor-system-materials .action-tile-wrapper .action-tile {
  @include media-breakpoint-down(sm) {
    box-shadow: none;
  }
}
.labor-system-materials .action-tile-wrapper .action-tile .card-body {
  background-color: $gray-0;
  @include media-breakpoint-down(sm) {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 24px;
    padding-bottom: 24px;
  }
}
.labor-system-materials
  .action-tile-wrapper
  .action-tile--with-tag
  .card-title {
  @include media-breakpoint-down(sm) {
    color: $business-500;
  }
}
