@import "../../../../../styles/custom";

.order-container {
  
  &__number {
   padding-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 2px solid $gray-900;
    color: $gray-900;
    margin-right: 16px;
    position: relative;
    @include media-breakpoint-down(md) {
      margin-bottom: 12px;
    }
  }

  &__order {
    margin-top: 16px;
    font-size: 20px;
    font-weight: 600;
  }
  &__number--rtl {
    direction: rtl;
    margin-right: 0px;
    margin-left: 16px;
  }
}
