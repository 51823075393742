@import "../../../../../styles/custom";
.button {
  padding: 10px 20px;
  margin-top: 12px;
  margin-bottom: 32px;
  border: none;
  border-radius: 5px;
  color: $gray-50;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 16px;

  @include media-breakpoint-down(lg) {
    margin-top: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.Primary {
  background-color: $business-500;
}

.Primary:hover {
  background-color: $business-600;
}

.Second {
  background-color: $business-700;
}

.Second:hover {
  background-color: $business-600;
}

.Tertiary {
  background-color: $business-700;
  margin-right: 0;

  &:hover {
    background-color: $business-600;
  }

  &:focus-visible {
    background-color: $warning-500;
    color: $gray-900;
    outline: 2px solid $gray-900;
  }
}
