@import "../../../../../styles/custom";

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-trigger {
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  color: $business-700  ;
}

.tooltip-text {
  visibility: hidden;
  width: 520px;
  height: 248px;
  padding: 32px;
  background: $gray-50;
  box-shadow: 0px 8px 24px $gray-900;
  border-radius: 8px;
  border: 0.5px $gray-100 solid;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -260px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text,
.tooltip-container:focus-within .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.text {
  color: $gray-900;
  font-size: 16px;
  font-weight: 400;
  word-wrap: break-word;
}
.header {
  color: $gray-900;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  word-wrap: break-word;
}