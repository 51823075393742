@import "../../../../../styles/custom";

.video-widget {
  position: relative;
  border-radius: 8px;
  direction: ltr;
  background: radial-gradient(circle, #134359a6, #13435900);

  &__image {
    width: 100%;
    height: 100%;
  }

  &__body {
    position: absolute;
    bottom: 0;
    left: 0;
    color: $gray-50;
  }

  &__content {
    display: flex;
    align-items: flex-end;
  }

  &__button {
    display: flex;
    justify-content: center;
    margin-right: 32px;
    align-items: center;
    background-color: $business-700;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: none;
    @include media-breakpoint-down(sm) {
      width: 32px;
      height: 32px;
      margin-right: 16px;
    }
  }

  &__icon {
    color: $gray-50;
    width: 64px;
    height: 64px;
    @include media-breakpoint-down(sm) {
      width: 32px;
      height: 32px;
    }
  }

  &__title.card-title {
    font-size: 20px;
    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
  }

  &__description.card-text {
    font-size: 16px;
    @include media-breakpoint-down(md) {
      font-size: 12px;
    }
  }

  &.rtl {
    direction: rtl;

    .video-widget__button {
      margin-right: 0;
      margin-left: 32px;
      @include media-breakpoint-down(sm) {
        margin-left: 16px;
      }
    }

    .video-widget__body {
      right: 0;
      left: auto;
    }
  }
}
