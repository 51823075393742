@import "../../../styles/custom";

.hero-search-input {
  position: relative;

  div[data-component="Search"] {
    div[data-component="Box"] {
      border: none;
    }
  }
  &__wrapper {
    padding: 0;
    background-color: white;
    border-radius: 4px;
    border: 1px solid $gray-300;

    @include media-breakpoint-up(lg) {
      border: none;
    }
  }

  .input-group {
    justify-content: space-between;
  }

  &__input-wrapper {
    width: 100%;
    display: flex;

    @include media-breakpoint-up(lg) {
      width: 75%;
    }
  }

  &__input-icon {
    padding: 0;
    background-color: transparent;
    border: none;
    color: $gray-700;

    @at-root .main-menu-navbar
        .hero-search-input__input-icon
        .input-search-icon.icon {
      color: $gray-700;
    }
  }

  &__input {
    border: none;
    padding: 0 12px;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  &__search-btn {
    display: none;
    margin-inline-start: auto;

    &:disabled {
      background-color: $business-700;
      border-color: $business-700;
      opacity: 1;
    }

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  .results-dropdown {
    position: absolute;
    top: 68px;
    background-color: $gray-50;
    width: 100vw;
    inset-inline-start: -16px;
    z-index: 999;
    height: calc(100vh - 155px);

    @include media-breakpoint-up(lg) {
      background-color: white;
      color: $gray-900;
      border-radius: 4px;
      box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.12),
        0px 2px 2px 0px rgba(0, 0, 0, 0.1);
      width: 100%;
      inset-inline-start: 0;
      height: auto;
    }

    &__header {
      padding: 16px;
      padding-bottom: 8px;
      border-bottom: 1px solid $gray-100;
      font-size: 16px;

      @include media-breakpoint-up(lg) {
        padding: 24px;
      }

      p {
        margin: 0;
      }

      span {
        font-weight: 600;
      }
    }

    &__results {
      max-height: calc(100vh - 297px);
      overflow-y: auto;

      @include media-breakpoint-up(lg) {
        max-height: 367px;
      }

      .result-row__result-content {
        padding-inline-end: 24px;
      }

      &--previous {
        .result-row strong {
          font-weight: inherit;
          background-color: transparent;
        }
      }
    }

    &__footer {
      padding: 16px 24px;
      border-top: 1px solid $gray-100;
      background-color: $gray-100;
      position: absolute;
      bottom: 0;

      @include media-breakpoint-up(lg) {
        background-color: $gray-50;
        position: initial;
      }
    }

    &__results-block {
      padding: 0;
      padding-top: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid $gray-100;

      &--with-results {
        padding-bottom: 0;
        border-bottom: 0;
      }
    }

    &__results-block-title {
      padding: 0 16px;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 12px;

      @include media-breakpoint-up(lg) {
        padding: 0 24px;
      }
    }

    &__show-more {
      padding: 16px 16px 109px 16px;
    }

    &__show-more-button {
      border: none;
      background-color: transparent;
      text-decoration: underline;
      text-underline-offset: 4px;
      color: $business-500;

      &:hover {
        color: $business-600;
        text-decoration-thickness: 2px;
      }
    }

    &__no-results {
      padding: 16px 24px 24px 24px;
      margin-top: 0;

      &.search-results__no-results{
        border-bottom: none;
      }
      h6 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 24px;
        @include media-breakpoint-down(lg) {
          font-size: 16px;
        }
        @include media-breakpoint-down(md) {
          padding-bottom: 8px;
          border-bottom: 1px solid $gray-100;
          margin-bottom: 16px;
        }
      }
    }

    &__loading-placeholder {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-color: white;
      z-index: 1000;
    }

    .certificate-validation-tool__loader {
      opacity: 1;
    }
  }

  &__submit-spinner {
    height: 24px !important;
    width: 54px !important;
    top: 2px !important;
    inset-inline-start: 12px !important;
    transform: scale(0.5);
  }
}
