@import "./fonts";
@import "./_custom.scss";
@import "~bootstrap/scss/bootstrap";
@import "./_mixins.scss";

* {
  font-family: "Inter", "Noto Kufi Arabic", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  transition: outline 0.05s ease-in;
}

html {
  background-color: white;
  scroll-padding: 115px;

  @include media-breakpoint-down(lg) {
    scroll-padding: 75px;
  }
}

#root {
  max-width: 100%;
  height: auto !important;
}

body {
  max-height: 1200px !important;
  max-width: 100vw;
  background-color: var(--bs-body-bg) !important;

  &.body-shaded {
    position: fixed;
    top: 0;
    inset-inline-end: 0;
    width: 100%;

    .dark-overlay {
      height: 100vh;
      width: 100vw;
    }
  }

  &.body-freeze {
    position: fixed;
    top: 0;
    inset-inline-end: 0;
    width: 100vw;
  }

  &.body-zoomed-out {
    zoom: 75%;
    max-width: unset;

    .numbers-block,
    .quote-container,
    .banner-component--body-variant {
      max-width: unset;
    }
  }

  &.body-zoomed-in {
    zoom: 125%;
  }
}

.dark-overlay {
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  width: 0;
  height: 0;
  background-color: $gray-900;
  opacity: 0.3;
  z-index: 100;
}

.container {
  padding-inline-start: 0 !important;
  padding-inline-end: 0 !important;

  @include media-breakpoint-down(xl) {
    max-width: calc(100% - 32px) !important;
  }
}

.row {
  --bs-gutter-x: 8px;

  @include media-breakpoint-up(xl) {
    --bs-gutter-x: 1.5rem;
  }
}

.btn-primary {
  border-color: $business-500;
  background-color: $business-500;
  padding: 0.75rem 1.5rem;
  border-radius: 4px !important;
  font-size: 1rem;
  font-weight: 500;

  &:hover {
    background-color: $business-600 !important;
  }

  &:active,
  &:focus {
    background-color: $business-700 !important;
  }

  &:focus {
    &:not(:focus-visible) {
      box-shadow: none;
    }

    &:focus-visible {
      @include focus();
    }
  }
}

@at-root body.arabic {
  * {
    font-family: "Inter", "Noto Kufi Arabic", sans-serif;
  }
  .translation {
    &--en {
      display: none;
    }

    &--ar {
      display: inline;
    }
  }
}

.translation {
  &--en {
    display: inline;
  }

  &--ar {
    display: none;
  }
}

div[data-component="Search"] {
  [data-tippy-root] {
    visibility: hidden !important;
  }
}
