@import "../../../styles/custom";

.search-filters {
  padding: 12px 0 0;
  border: 1px solid $gray-50;

  @include media-breakpoint-up(lg) {
    padding: 24px 24px 32px 24px;
    border: 1px solid $gray-200;
    border-radius: 8px;
    width: calc(100% - 40px);
    max-width: 320px;
  }

  &__desktop-container {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  &__mobile-container {
    display: block;

    @include media-breakpoint-up(lg) {
      display: none;
    }

    .search-filters {
      &__open-filters-btn {
        background-color: white;
        width: 100%;
        padding: 11px 24px;
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: center;
        border: 1px solid $gray-300;
        border-radius: 4px;
        color: $business-500;
      }
    }

    .search-mobile-filters {
      padding: 32px 0;
      background-color: white;
      position: absolute;
      top: 62px;
      inset-inline-start: 0;
      width: 100vw;
      // height: calc(100vh - 62px);
      height: var(--window-inner-height);
      z-index: 100;

      &__pane-title {
        margin-top: 16px;
        margin-bottom: 16px;
        font-size: 24px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &__filters-list {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          border-bottom: 1px solid $gray-100;

          button {
            padding: 24px 0;
            background-color: transparent;
            border: none;
            display: flex;
            align-items: flex-start;
            gap: 24px;
            justify-content: space-between;
            width: 100%;

            .search-mobile-filters__filters-list-label {
              font-size: 14px;

              &--main {
                font-size: 16px;
                font-weight: bold;
              }
            }

            .icon {
              min-width: 24px;
            }
          }
        }
      }

      &__back-btn {
        background-color: transparent;
        color: $business-500;
        border: none;
        display: flex;
        align-items: center;
        padding: 0;
        margin-bottom: 8px;
      }

      .search-filters__topics-list {
        display: block;
        position: initial;
        box-shadow: none;
        padding: 0 5px;
      }

      .search-filters__material-list-item {
        padding-top: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid $gray-100;
        margin-bottom: 0;
      }

      .search-filters__topics-list-item {
        padding-top: 12px;
        padding-bottom: 12px;
      }

      &__apply-btn-wrapper {
        position: absolute;
        bottom: 0;
        inset-inline-start: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px;
        border-top: 1px solid $gray-300;
      }

      &__apply-btn {
        width: 100%;
        background-color: $business-500;
        border: none;
        border-radius: 4px;
        padding: 12px 24px;
        font-size: 16px;
        color: white;
      }

      &__clear-btn {
        background-color: transparent;
        text-decoration: underline;
        color: $business-500;
        border: none;
        padding: 0;
        font-size: 16px;
      }
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  &__materials-list {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 24px;
  }

  &__block-title {
    margin-bottom: 12px;
  }

  &__material-list-item {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
    position: relative;
    padding-inline-start: 16px;

    input {
      appearance: none;

      &:checked {
        & + label {
          font-weight: 500;
        }

        &::after {
          border-color: $business-500;
          border-width: 6px;
          transition: all 0.15s ease-in-out;
        }
      }

      &::after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        inset-inline-start: -3px;
        content: "";
        display: block;
        height: 20px;
        width: 20px;
        background-color: white;
        border: 1px solid $gray-600;
        border-radius: 50%;
        z-index: 100;
        transition: all 0.15s ease-in-out;
      }

      &:focus-visible {
        &::before {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          inset-inline-start: -3px;
          content: "";
          display: block;
          height: 20px;
          width: 20px;
          background-color: transparent;
          box-shadow: 0px 0px 0px 3px #f3c354, inset 0px 0px 0px 3px #000000;
          border-radius: 50%;
          z-index: 101;
        }
      }
    }
  }

  &__topics-dropdown {
    background-color: white;
    border: 1px solid $gray-300;
    border-radius: 4px;
    padding: 12px 16px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:focus-visible {
      @include focus();
    }
  }

  &__topics-list {
    position: absolute;
    top: 62px;
    inset-inline-start: 0;
    z-index: 99;
    width: 100%;
    padding: 16px;
    max-height: calc(100vh - 260px);
    overflow-y: auto;
    background-color: white;
    list-style: none;
    margin: 0;
    display: none;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.12),
      0px 2px 2px 0px rgba(0, 0, 0, 0.1);

    @include media-breakpoint-up(lg) {
      max-height: 264px;
    }

    &--open {
      display: block;
    }
  }

  &__topic-label {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    &--disabled {
      color: $gray-600;
    }
  }

  &__topic-value {
    flex-grow: 1; 
    margin-inline-end: 16px;
  }

  &__topic-count {
    margin-inline-start: auto; 
  }

  &__topics-list-item {
    position: relative;
    padding-inline-start: 36px;
    margin-bottom: 24px;
    text-align: left;

    @at-root body.arabic {
      .search-filters__topics-list-item {
        text-align: right;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    label {
      width: 100%;
    }

    input {
      appearance: none;
      display: block;

      &:checked {
        & + label {
          font-weight: 500;
        }

        &::after {
          background-image: url("data:image/svg+xml, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22none%22%20viewBox%3D%220%200%2024%2024%22%3E%0A%20%20%3Crect%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22%23134359%22%20rx%3D%224%22%2F%3E%0A%20%20%3Cpath%20fill%3D%22%23fff%22%20fill-rule%3D%22evenodd%22%20d%3D%22M17.12%208.17814c.2698.25054.2854.67236.0349.94217L10.9643%2015.787a.66651.66651%200%200%201-.4859.213.66684.66684%200%200%201-.48764-.2093l-3.14312-3.3333c-.25259-.2679-.2402-.6898.02768-.9424.26788-.2526.68981-.2402.9424.0276l2.65438%202.815%205.7057-6.14457c.2506-.2698.6724-.28542.9422-.03489Z%22%20clip-rule%3D%22evenodd%22%2F%3E%0A%3C%2Fsvg%3E");
          transition: all 0.15s ease-in-out;
          border: none;
        }
      }

      &:disabled {
        &::after {
          background-color: $gray-200;
          border-color: $gray-300;
        }
      }

      &::after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        inset-inline-start: -3px;
        content: "";
        display: block;
        height: 24px;
        width: 24px;
        background-color: white;
        border: 1px solid $gray-600;
        border-radius: 4px;
        z-index: 100;
        transition: all 0.15s ease-in-out;
      }
    }
  }

  &__selected-topics {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 24px;
  }

  &__topic-chip {
    border: 1px solid $gray-300;
    padding: 8px 16px;
    min-height: 40px;
    border-radius: 99px;
    width: auto;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 500;
  }

  &__remove-chip-btn {
    background-color: transparent;
    border: none;
    padding: 0;
    position: relative;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: $gray-200;
    }

    &:focus {
      &:focus-visible {
        @include focus();
      }
    }
  }

  &__filters-count {
    background-color: $business-500;
    color: white;
    padding: 0 6px;
    border-radius: 4px;
  }

  .topic-dropdown-icon {
    pointer-events: none;
  }
}
