@import "../../../styles/custom";

.labor-education-hero {
  background-color: $business-800;
  padding-top: 64px;
  padding-bottom: 84px;
  color: $gray-0;

  @include media-breakpoint-down(xl) {
    padding-left: 16px;
    padding-right: 16px;
  }

  &__learn-more {
   
    color: $gray-0 !important;
    padding: 11px 24px 13px 24px;
    gap: 10px;
    border-radius: 4px;
    background: $business-500;
    &:hover {
      background: $business-600;
    }
    @include media-breakpoint-down(lg) {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
    }
  }

  &__heading {
    font-size: 48px;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: -0.5px;
    margin-bottom: 24px;
    @include media-breakpoint-down(lg) {
      font-size: 36px;
      letter-spacing: -0.2px;
      margin-bottom: 8px;
    }
  }
  &__text {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
  &__content {
    font-size: 20px;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 32px;
    @include media-breakpoint-down(lg) {
      margin-bottom: 24px;
    }
  }
}
