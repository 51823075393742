@import "../../../styles/custom";

.saudi-labor-system {
  background-color: $gray-0;
  .saudi-labor-system--rtl {
    direction: rtl;
    &__hero {
      padding-left: 0px;
      padding-right: 156px;
    }
  }
  &__hero {
    padding-left: 156px;
  }

  &__tools-and-calculators {
    background-color: $gray-50;
    .action-tiles-group--neutral {
      padding-top: 72px;
      padding-bottom: 120px;
      @include media-breakpoint-down(lg) {
        padding-top: 40px;
        padding-bottom: 64px;
      }
    }
  }

  &__questions-header {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    margin-bottom: 24px;
  }
  &__questions-body {
    padding-inline-start: 0;
    padding-inline-end: 0;
    .container{
      margin-inline-start: 0px;
      margin-inline-end: 0px;
    
    }
  }
  &__questions {
    padding-left: 156px;
    padding-right: 156px;
    padding-top: 56px;
    padding-bottom: 120px;
    .accordion{
      background-color: $gray-0;
    }
    @include media-breakpoint-down(lg) {
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 40px;
      padding-top: 40px;
    }
    .accordion{
      background-color: $gray-0;
    }
    
  }
}
