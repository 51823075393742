@import "../../../styles/custom";

.search-page {
  &__mobile-filters-wrapper {
    display: block;

    @include media-breakpoint-up(lg) {
      display: none;
    }

    .search-page__sort-group {
      width: 100%;
      margin-top: 16px;

      .search-results__sort-dropdown {
        width: 100%;
      }
    }
  }

  &__hero {
    padding: 32px 0;
    background-color: #f7f7f8;

    @include media-breakpoint-up(lg) {
      padding: 64px 0 80px;
    }
  }

  &__title {
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 32px;
  }

  .hero-search-input {
    &__wrapper {
      width: 100%;
      border: 1px solid $gray-300;

      &.focused {
        @include focus();
      }

      .input-group {
        justify-content: space-between;
      }
    }
  }

  &__content {
    padding: 24px 0;

    @include media-breakpoint-up(lg) {
      padding: 40px 0 92px;
    }
  }

  .search-results__results-block-title {
    padding: 0;
  }

  .result-row {
    .result-row__result-footer {
      align-items: center;
    }
  }
}
.search-page .feedback .container {
  max-width: 100vw;
  padding: 40px 156px !important;
}
