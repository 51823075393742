@import "../../styles/custom";

.certificate-validation-tool {
  background-color: $gray-50;
  padding-bottom: 48px;
  position: relative;

  @include media-breakpoint-up(lg) {
    padding-bottom: 120px;
  }

  &__content {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    width: calc(100vw - 16px);

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      flex-wrap: nowrap;
      width: auto;
    }
  }

  &__text-content {
    width: calc(100% - 32px);

    @include media-breakpoint-up(lg) {
      max-width: 50%;
      width: 100%;
    }
  }

  &__calc-content,
  &__calc-result {
    background: white;
    border: 1px solid $gray-200;
    border-right-width: 0;
    border-left-width: 0;
    width: 100%;
    min-width: 100vw;
    padding: 32px 16px;
    position: relative;
    inset-inline-start: -16px;

    @include media-breakpoint-up(lg) {
      max-width: 50%;
      border-radius: 8px;
      border-right-width: 1px;
      border-left-width: 1px;
      padding: 40px 32px;
      inset-inline-start: 0;
      min-width: auto;
    }
  }

  &__calc-content {
    .form-select,
    .form-control {
      &:focus {
        box-shadow: $focus-outline;
        outline: none;
      }
    }
  }

  &__return-btn {
    background-color: white;
    border: none;
    display: flex;
    align-items: center;
    color: $business-500;

    &--top {
      margin-inline-start: -8px;
      margin-bottom: 8px;
      display: none;

      @include media-breakpoint-up(lg) {
        display: flex;
      }
    }

    &--bottom {
      border: 1px solid $gray-300;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 48px;
      border-radius: 4px;

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    @at-root body.arabic {
      .certificate-validation-tool__return-btn--top {
        margin-inline-start: 8px;
        .icon {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__result-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__result-item {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    align-items: center;
    padding: 24px 0;
    border-bottom: 1px solid $gray-200;

    &:first-of-type {
      @include media-breakpoint-down(lg) {
        padding-top: 0;
      }
    }

    &:first-of-type,
    &:last-of-type {
      border-bottom: none;
    }

    .certificate-validation-tool__result-item-label,
    .certificate-validation-tool__result-item-value {
      margin: 0;
      font-size: 16px;
    }
  }

  &__result-item-label {
    font-weight: 400;
  }

  &__result-item-value {
    font-weight: 600;
    text-align: right;

    span {
      font-weight: 600;
    }
  }

  &__title {
    font-size: 48px;
    font-weight: 700;
    letter-spacing: -0.5px;
    margin: 0;
    max-width: 457px;
    @include media-breakpoint-down(lg) {
      font-size: 36px;
    }
  }

  &__description {
    font-size: 16px;
    font-weight: 400;
    margin: 16px 0 0;
    max-width: 457px;
    @include media-breakpoint-down(lg) {
      margin-bottom: 36px;
    }
  }

  &__validation-result {
    font-size: 24px;
    line-height: 20px;
    font-weight: 600;
    margin: 0;
  }

  &__cert-status {
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    padding: 0 6px;
    border-radius: 4px;
    display: flex;
    gap: 8px;
    align-items: center;
    color: white;

    span {
      font-weight: 600;
    }

    &--expired {
      background-color: $danger-500;
    }

    &--active,
    &--verified {
      background-color: $success-900;
    }

    &--inactive {
      background-color: $danger-500;
    }

    &--default {
      background-color: $warning-700;
    }
  }

  &__error-msg {
    color: $danger-500;
    font-size: 14px;
    margin: 5px 0;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    inset-inline-start: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.5;
    z-index: 50;
  }
}
