  @import "../../../styles/custom";

  .labor-education {
    background-color: $gray-50;
    .labor-education--rtl {
      direction: rtl;
      &__hero {
        padding-inline-start: 0px;
        padding-inline-end: 156px;
      }
    }
    &__hero {
      padding-inline-start: 156px;
    }
  }
