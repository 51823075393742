@import "../../../../../styles/custom";
.learn-more {
  width: 100%;
  background-color: $info-100;
  padding: 16px;
  position: relative;
  border-radius: 8px 0px 0px 8px;
  margin-bottom: 32px;

  .accordion {
    display: inline;
  }
  .accordion-item {
    border: none;
  }
  .accordion-button {
    background-color: $gray-0;
    padding-left: 0;
    padding-right: 0;
  }
  .accordion-body {
    padding: 0;
  }
  @include media-breakpoint-down(sm) {
    background-color: $gray-0;
    border: none;
    padding: 0;
  }

  &.learn-more--rtl {
    direction: rtl;
    border-radius: 0px 8px 8px 0px;

    hr {
      position: absolute;
      top: -16px;
      left: auto;
      right: 1px;
      border: 2px solid $business-500;
      border-radius: 0px 8px 8px 0px;
      height: 99%;
    }

    .learn-more__icon {
      transform: scaleX(-1);
    }
  }

  @include media-breakpoint-down(sm) {
    hr {
      display: none;
    }
  }

  hr {
    position: absolute;
    top: -16px;
    left: 1px;
    border: 2px solid $business-500;
    border-radius: 8px 0px 0px 8px;
    height: 99%;
  }

  .learn-more-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-text {
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      color: $gray-900;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin-bottom: 8px;

    li {
      align-items: center;
      padding-top: 16px;

      a {
        align-items: center;
        text-decoration: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        text-decoration-line: underline;
        color: $business-600;

        &:focus-visible {
          background-color: $warning-500;
          color: $gray-900;
          outline: none;
        }

        .right-arrow {
          margin-left: 10px;
          margin-right: 10px;
        }
      }
    }
  }
}
