@import "../../../../styles/custom";

.service-category-hero {
  background-color: $business-800;
  padding: 64px 156px 80px 156px;
  color: $gray-0;

  @include media-breakpoint-down(lg) {
    padding: 32px 16px 48px 16px
  }

  &__heading {
    color: $gray-0;
    font-size: 48px;
    font-weight: 700;
    line-height: 1.2;
    @include media-breakpoint-down(lg) {
      font-size: 32px;
    }
  }
}
