@import "../../../../styles/custom";

.service-category-page {
  padding-left: 156px;
  padding-right: 156px;
  padding-top: 56px;
  padding-bottom: 56px;
  background-color: $gray-50;

  @include media-breakpoint-down(lg) {
    padding: 32px 16px;
  }

  &__group {
    padding-bottom: 120px;
    padding-top: 56px;

    @include media-breakpoint-down(md) {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 32px;
      }
  }
  &__column-item{
    @include media-breakpoint-down(md) {
        margin-bottom: 32px;
      }
  }
 
}
