@import "../../../../../../styles/custom";
.didyouknow {
  height: 458px;
  color: $business-50;
  padding-left: 156px;
  padding-right: 156px;
  padding-top: 96px;
  padding-bottom: 96px;
  background: $business-800;

  &__banner-container {
    &__heading {
      flex: 1 1 0;
      font-size: 48px;
      font-weight: 600;
      word-wrap: break-word;
    }

    &__quote {
      position: relative;
      padding: 48px;
      background: $business-600;
      border-radius: 8px;
      justify-content: flex-start;
      align-items: center;
      display: flex;

      &__text {
        font-size: 24px;
        color: $business-50;
        font-weight: 400;
        z-index: 1;
      }

      &__icon-container {
        position: absolute;
        right: 0;
        top: 0;
        padding: 48px;
        z-index: 0;
        height: 132px;
        transform-origin: 0 0;
        flex-direction: column;
        gap: 10px;
        display: inline-flex;

        &__icon {
          width: 147px;
          height: 121px;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    padding-left: 16px;
    padding-right: 16px;

    &__banner-container {
      padding: 24px;

      &__heading {
        font-size: 28px;
      }

      &__quote {
        padding: 24px !important;
      }
      &__text {
        font-size: 20px;
        position: relative !important;
        z-index: 1;
      }

      &__icon-container {
        position: absolute !important;
        right: 24px;
        z-index: 0;
        &__icon {
          width: 140px;
          height: 100px;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 56px;
    height: 100%;

    &__banner-container {
      &__heading {
        font-size: 36px;
        line-height: 48px;
        padding-bottom: 24px;
      }

      &__quote {
        padding: 70px;
        &__text {
          position: absolute;
          font-size: 16px;
          line-height: 24px;
        }

        .icon-container {
          position: absolute;
          .icon {
            width: 140px;
            height: 120px;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding-left: 96px;
    padding-right: 96px;

    &__banner-container {
      &__heading {
        font-size: 48px;
        line-height: 60px;
      }

      &__quote {
        &__text {
          font-size: 24px;
          line-height: 32px;
        }

        &__icon-container {
          &__icon {
            width: 160px;
            height: 140px;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    padding-left: 156px;
    padding-right: 156px;

    &__banner-container {
      &__heading {
        font-size: 60px;
        line-height: 72px;
      }

      &__quote {
        &__text {
          font-size: 28px;
          line-height: 36px;
        }

        &__icon-container {
          &__icon {
            width: 180px;
            height: 160px;
          }
        }
      }
    }
  }
}
