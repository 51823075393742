@import "../../../../styles/custom";
.hiring-non-saudi-employees {
  margin-bottom: 56px;
  @include media-breakpoint-down(lg) {
    margin-bottom: 32px;
  }
  &__header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 32px 32px 32px;
    margin: 0px 0px;
    background-color: $business-100;
    &--business {
      background-color: $business-100;
    }
    &--individuals {
      background-color: $business-100;
    }
    &--providers {
      background-color: $business-100;
    }
    @include media-breakpoint-down(lg) {
      padding: 16px;
    }
  }
  &__empty-service-answer {
    color: $gray-600;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
  }
  &__service-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    @include media-breakpoint-down(lg) {
      justify-content: flex-start;
      &__icon {
        margin-inline-start: 16px;
      }
    }
    @include media-breakpoint-down(sm) {
      justify-content: space-between;
    }
    &:hover {
      cursor: pointer;
      a {
        color: $business-600 !important;
        text-decoration: underline;
      }
      .hiring-non-saudi-employees__service-header-container__icon {
        color: $business-600;
      }
    }
    &__icon {
      color: $business-500;
    }
    a {
      color: $gray-700 !important;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
    }
    @at-root body.arabic {
      .hiring-non-saudi-employees__service-header-container__icon {
        transform: scale(-1);
      }
    }
  }
  &__services {
    padding: 0px 32px 0px 32px;
    @include media-breakpoint-down(lg) {
      padding: 0px;
    }
  }
  &__line {
    color: $gray-300;
  }
  &__show-more {
    margin-top: 12px;
    color: $gray-600 !important;
  }
  &__body-card {
    border-width: 0px;
    border-radius: 8px;
    box-shadow: 0 0px 3px #00000012;
    &__header {
      margin-bottom: 0px;
      @include media-breakpoint-down(lg) {
        margin-bottom: 8px;
      }
    }
  }

  &__body-card .card-body {
    padding: 8px 8px 24px 8px;
    @include media-breakpoint-down(lg) {
      padding: 16px 16px 24px 16px;
    }
    @include media-breakpoint-down(md) {
      padding: 16px;
    }
  }
  &__body-card .hiring-non-saudi-employees__body-with-one-null {
    height: 209px;
    @include media-breakpoint-down(md) {
      height: 100%;
    }
  }

  &__body-with-one {
    background-color: $gray-50;
    margin-top: 32px;
    @include media-breakpoint-down(lg) {
      margin-top: 16px;
    }
  }
  &__body-with-array {
    background-color: $gray-50;
    height: 100%;
    padding: 24px 32px 32px 32px !important;

    @include media-breakpoint-down(md) {
      height: 100%;
    }
  }

  &__card {
    margin-top: 32px;
    @include media-breakpoint-down(lg) {
      margin-top: 16px;
    }
  }
  &__header-wrapper {
    display: flex;
    flex-direction: row;
  }

  &__header {
    color: $gray-900;
    font-size: 32px;
    font-weight: 600;
    line-height: 130%;
    padding-left: 0;
    padding-right: 0;
    flex: 1;
    @include media-breakpoint-down(lg) {
      font-size: 24px;
    }
    @include media-breakpoint-down(md) {
      margin-bottom: 16px;
    }
  }

  &__last-word-with-icon {
    font-size: 32px;
    font-weight: 600;
    line-height: 130%;
    display: inline-flex;
    align-items: baseline;
  }

  &__icon-wrapper {
    color: $business-600;
    margin-left: 16px;
    width: 32px;
    height: 32px;
    border: 2px solid $gray-300;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hiring-non-saudi-employees__header-wrapper--rtl {
    direction: rtl;
    .hiring-non-saudi-employees__icon-wrapper {
      margin-right: 16px;
      margin-left: 0px;
    }
    .hiring-non-saudi-employees__icon {
      transform: scaleX(-1);
    }
  }
  h5 a {
    color: $gray-900;
    text-decoration: none;
  }
  h5 {
    margin-bottom: 16px;
  }

  a {
    color: $business-500;
    text-decoration: none;
  }
  &__button {
    padding: 9px 16px 10px 16px !important;
    @include media-breakpoint-up(md) {
      width: fit-content !important;
    }
    p {
      font-size: 14px;
    }
  }
}
.hiring-non-saudi-employees.container-fluid {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}
