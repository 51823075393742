@import "../../../styles/custom";

.calc-wrapper {
  width: 100%;
  min-width: 100vw;
  padding: 32px 16px;
  position: relative;
  inset-inline-start: -16px;
  @include media-breakpoint-up(lg) {
    min-width: auto;
    inset-inline-start: 0;
  }

  div[data-component="Accordion"] {
    @include media-breakpoint-down(lg) {
      width: 100vw;
      margin-inline-start: -16px;
    }

    & > div[data-component="Box"] {
      background-color: white;
      border-radius: 8px;
      margin-top: 8px;
      border: 1px solid $gray-200;

      @include media-breakpoint-down(lg) {
        border-radius: 0px;
        border-left: 0px;
        border-right: 0px;
      }

      button {
        padding: 24px 32px;

        @include media-breakpoint-down(lg) {
          padding: 24px 16px;
        }

        div[data-component="Box"] {
          margin: 0;
        }
      }
    }

    div[data-component="Collapsible"] {
      position: relative;
      top: -5px;
      background-color: white;
      border-radius: 0;
      border-radius: 0 0 8px 8px;
      border: 1px solid $gray-200;
      border-top: 0px;

      @include media-breakpoint-down(lg) {
        border-left: 0px;
        border-right: 0px;
        border-radius: 0px;
      }

      .nitaqat-accordion-content-wrapper {
        padding: 0px 32px 24px 32px;

        @include media-breakpoint-down(lg) {
          padding: 0px 16px 24px 16px;
        }
      }

      & > div[data-component="Box"] {
        padding: 0;
      }
    }
  }
}

.calc-content {
  background: white;
  border: 1px solid $gray-200;
  border-right-width: 0;
  border-left-width: 0;
  width: 100%;
  min-width: 100vw;
  padding: 32px 16px;
  position: relative;
  inset-inline-start: -16px;

  &--required {
    display: flex;
    gap: 4px;
    font-size: 14px;
    margin-top: 5px;
    color: $danger-500;
    font-weight: 500;
    margin-bottom: 8px;
  }

  &--rtl {
    .calc-content__form:first-child {
      margin-left: 8px;
      margin-right: unset !important;
    }
  }

  &__radio-wrapper {
    cursor: pointer;
  }

  @include media-breakpoint-up(lg) {
    border-radius: 8px;
    border-right-width: 1px;
    border-left-width: 1px;
    padding: 40px 32px;
    min-width: auto;
    inset-inline-start: 0;
  }

  &__date-container {
    display: flex;
    justify-content: space-between;
  }

  &__date-control {
    width: 50%;

    &--mr {
      margin-right: 5px;
    }
  }

  &__flex {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .calc-content__form {
      width: 50%;
      font-size: 15px;

      &__label {
        width: 230px;

        &--aligned {
          @include media-breakpoint-up(lg) {
            margin-bottom: 37px;
          }
        }
      }
    }

    .calc-content__form:first-child {
      margin-right: 8px;
    }

    @include media-breakpoint-down(lg) {
      flex-direction: column;
      gap: 24px;

      .calc-content__form {
        width: 100%;
      }
    }
  }

  &__form {
    div[data-component="Message"] {
      margin-bottom: 16px;
      padding-bottom: 16px !important;
    }

    div[data-component="Field"] {
      width: 100%;
    }

    &-check {
      display: flex;
      padding: 15px 35px;
      border: 2px solid $gray-300;
      background-color: $white;
      border-radius: 4px;
      margin-bottom: 10px;

      &--mr {
        .form-check-input {
          margin-right: 5px;
        }
      }

      &--ml {
        .form-check-input {
          margin-left: 5px;
        }
      }
    }

    &__checkbox {
      .form-check-input:checked {
        background-color: $business-700;
      }
    }

    &__info {
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;
      color: $gray-600;
      margin-top: 8px;

      span {
        color: $gray-900;
      }
    }

    &-check-selected {
      border: 2px solid $business-500;
      background-color: $business-100;
      .form-check-input {
        background-color: $business-500;
      }
    }
  }
}

.nitaqat-accordions {
  height: unset !important;
  padding-bottom: unset !important;
  display: inline-block !important;
  padding-top: unset !important;
  margin-top: 8px;

  @include media-breakpoint-down(lg) {
    max-width: 100% !important;
  }
}
@at-root {
  body[lang="ar"] {
    .form-check .form-check-input {
      margin-right: -1.5rem;
      float: right;
    }
    .form-check {
      padding-right: 1.5rem;
    }
  }
}
