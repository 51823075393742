@import "../../../../../styles/custom";
.information {
  margin-top: 16px;
  padding: 16px;
  background-color: $warning-100;
  position: relative;
  border-radius: 8px 0px 0px 8px;
  &.information--rtl {
    direction: rtl;
    hr {
      position: absolute;
      top: -16px;
      left: auto;
      right: 1px;
      border: 2px solid $warning-500;
      border-radius: 0px 8px 8px 0px;
      height: 99%;
    }
    .information__icon {
      transform: scaleX(-1);
    }
    .information__arrow-icon {
      transform: scaleX(-1);
    }
  }

  hr {
    position: absolute;
    top: -16px;
    left: 1px;
    border: 2px solid $warning-500;
    border-radius: 8px 0px 0px 8px;
    height: 99%;
  }

  &__header {
    padding-inline-start: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: $gray-900;
    ul {
      list-style: disc !important;
      margin-inline-start: 16px;
      padding-inline-start: 0 !important;
    }

    ul li {
      display: list-item !important;
      margin-top: 8px;
    }
    ul li::marker {
      margin-inline-start: 12px;
      color: $business-600;
    }
  }
  &__icon {
    height: 24px;
    width: 24px;
    flex-shrink: 0;
  }

  &__header-container {
    display: flex;
  }

  ul {
    list-style: none;
    margin-bottom: 8px;

    li {
      display: flex;
      align-items: center;

      a {
        align-items: center;
        text-decoration: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        text-decoration-line: underline;
        color: $warning-900;

        .right-arrow {
          margin-left: 10px;
        }
      }
    }
  }
}
