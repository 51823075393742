.time-display {
  margin-bottom: 96px;
  margin-top: 40px;
  &__line{
    display: flex; 
    align-items: center;
  }
  &__info {
    margin-right: 4px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
}

.time-display--rtl {
    direction: rtl;
    .time-display__info {
      direction: rtl;
      margin-left: 4px;
      margin-right: 0;
    }
  }
  
