@import "../../../../../../styles/custom";

.video-inside {
  margin-bottom: 16px;
  @include media-breakpoint-down(lg) {
    margin-bottom: 24px;
  }

  &__image-container {
    cursor: pointer;
  }

  &__footer {
    margin-top: 8px;
  }

  &__row {
    @include media-breakpoint-down(md) {
      flex-direction: column-reverse;
    }
  }

  &__left-col {
    padding-inline-start: 14px;
    padding-inline-end: 12px;
    @include media-breakpoint-down(md) {
      padding-inline-start: 0;
      padding-inline-end: 0;
    }
  }

  &__right-col {
    display: flex;
    flex-direction: column;
    color: $gray-0;
    max-height: 100%;
    padding-inline-start: 12px;
    padding-inline-end: 0;

    @include media-breakpoint-down(md) {
      padding-inline-start: 0;
      padding-inline-end: 0;
    }

    &__title {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      margin-bottom: 12px;

      @include media-breakpoint-down(md) {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
      }
    }

    &__duration {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      display: inline;
      @include media-breakpoint-down(md) {
        margin-bottom: 24px;
      }
    }
  }
  &__watch-video-button {
    margin-top: auto;

    @include media-breakpoint-down(md) {
      margin-top: 12px;
    }

    button {
      padding: 9px 16px 10px 16px;
      @include media-breakpoint-down(md) {
        padding: 11px 24px 13px 24px;
      }

      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
  }

  &__player-icon {
    background-color: $business-500;
    color: $gray-0;
    border-radius: 50%;
    path {
      fill: $gray-0 !important;
    }
  }

  div[data-component="Image"] {
    &::before {
      background-image: none !important;
    }
    width: 100%;
  }

  &__row {
    @include media-breakpoint-down(md) {
      flex-direction: column-reverse;
    }
  }

  &__left-col {
    padding-inline-start: 14px;
    padding-inline-end: 12px;
    @include media-breakpoint-down(md) {
      padding-inline-start: 0;
      padding-inline-end: 0;
    }
  }

  &__right-col {
    display: flex;
    flex-direction: column;
    color: $gray-0;
    max-height: 100%;
    padding-inline-start: 12px;
    padding-inline-end: 0;

    @include media-breakpoint-down(md) {
      padding-inline-start: 0;
      padding-inline-end: 0;
    }

    &__title {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      margin-bottom: 12px;

      @include media-breakpoint-down(md) {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
      }
    }

    &__duration {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      @include media-breakpoint-down(md) {
        margin-bottom: 24px;
      }
    }
  }
  &__watch-video-button {
    margin-top: auto;

    @include media-breakpoint-down(md) {
      margin-top: 12px;
    }

    button {
      padding: 9px 16px 10px 16px;
      @include media-breakpoint-down(md) {
        padding: 11px 24px 13px 24px;
      }
      
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
  }

  &__player-icon {
    background-color: $business-500;
    color: $gray-0;
    border-radius: 50%;
    path {
      fill: $gray-0 !important;
    }
  }

  div[data-component="Image"] {
    width: 100%;
  }

  &__banner-title {
    color: $gray-0;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    margin-bottom: 37px;
  }

  &__container {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 2px;
      height: 100%;
      background-color: $business-500;
    }
    border-radius: 0px 8px 8px 0px;
    padding: 24px;
    @include media-breakpoint-down(md) {
      border-radius: 8px;
      padding: 32px 16px 40px 16px;
    }
    position: relative;
    background-color: $business-800;
  }

  &__modal-buttons {
    button {
      margin-bottom: 12px;
    }
  }

  div[data-component="VideoPlayer"] {
    div[data-component="IconButton"] {
      visibility: hidden;
    }
    div[data-component="Video"] {
      div[data-component="Box"] {
        visibility: visible;
        opacity: 1;
      }
    }
    div[data-component="Box"] {
      div[data-component="Box"] {
        p {
          color: $gray-500;
          :focus {
            outline: none;
          }
        }
      }
      p {
        color: $gray-0;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
      }
    }
  }
  div[data-component="Video"] {
    visibility: hidden;
    @include media-breakpoint-down(lg) {
      margin-bottom: 12px;
    }
    div[data-component="Box"] {
      div[data-component="Box"] {
        button {
          visibility: hidden;
        }
      }
    }
  }

  &__button-group {
    div[data-component="Box"] {
      button {
        background-color: $gray-0;
        margin-inline-end: 12px;
        width: 36px;
        height: 36px;
        border-radius: 50%;
      }
    }
  }
  &__second {
    height: 72px;
    align-items: center;
    background-color: $gray-0;
    padding: 12px 24px 12px 24px;
  }

  &__help {
    color: $gray-900;
    font-size: 14px;
    margin-top: 24px;
    margin-bottom: 8px;
  }

  &__watch-video {
    color: $gray-0;
    font-size: 20px;
    font-weight: 400;
    line-height: 140%;
    margin-inline-end: auto;
    @include media-breakpoint-down(lg) {
      font-size: 14px;
      font-weight: 500;
      line-height: 150%;
    }
  }
  &__duration {
    color: $gray-0;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    margin-inline-start: auto;
    @include media-breakpoint-down(lg) {
      font-size: 14px;
    }
  }
  @include media-breakpoint-down(xl) {
    div[data-component="Modal"] {
      div[data-component="Box"] {
        div[data-component="VideoPlayer"] {
          max-width: 100%;
        }
      }
      button {
        width: 100%;
      }
    }
  }

  div[data-component="Modal"] {
    div[data-component="Divider"] {
      margin-top: 8px;
    }
    div[id="modalBodyWrapper"] {
      padding-bottom: 0;
    }
    div[data-component="VideoPlayer"] {
      margin-top: -24px;
      div[data-component="Video"] {
        max-width: 110%;
      }
      div[data-component="Box"] {
        @include media-breakpoint-up(xl) {
          max-width: 387px;

          button {
            padding-inline-end: 0px;
            align-items: flex-start;
          }
        }
        @include media-breakpoint-down(xl) {
          button {
            align-items: flex-start;
          }
        }
      }
    }
    div[data-component="Box"] {
      p {
        padding-inline-end: 24px;
      }
      button {
        p {
          padding-inline-end: 0px;
        }
      }
    }
    z-index: 1200;
  }
}

.article .video-inside a {
  margin-inline-start: 8px;
  color: $gray-0;
  text-decoration: underline;
}

.video-inside__watch-video-button button {
  @include media-breakpoint-up(md) {
    width: max-content;
  }

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-overflow: unset !important;

    @include media-breakpoint-down(md) {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
  }
  [data-component="Icon"] {
    width: 15px;
    height: 15px;

    @at-root {
      body[lang="ar"] {
        .video-inside__watch-video-button button {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
    svg {
      path {
        width: 10px;
        height: 10px;
      }
    }
  }
}
