@import "../../../../styles/custom";

.server-container {
  background-color: $gray-50 !important;
  background: url("../../../../../public/img/big-grey-logo.png") no-repeat right
    9px;
  background-size: contain;
  padding-left: 156px;
  direction: ltr;

  @include media-breakpoint-down(md) {
    background: none;
    padding-left: 16px;
    padding-right: 16px;
  }
  &.server-container--rtl {
    background: url("../../../../../public/img/big-grey-logo.png") no-repeat
      left 9px;
    background-size: contain;
    direction: rtl;
    padding-right: 156px;
    padding-left: 0px;
    @include media-breakpoint-down(md) {
      background: none;
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .server-content {
    padding-top: 40px;
    padding-bottom: 80px;

    .server-header {
      font-size: 48px;
      font-weight: 600;
      color: $gray-900;
      line-height: 130%;
      @include media-breakpoint-down(md) {
        font-size: 36px;
      }
    }
    .server-error-code {
      font-size: 20px;
      font-weight: 400;
      color: $gray-700;
      line-height: 140%;
      @include media-breakpoint-down(md) {
        font-size: 16px;
      }
    }
    .server-button-message {
      font-size: 20px;
      font-weight: 400;
      color: $gray-900;
      line-height: 140%;
      @include media-breakpoint-down(md) {
        font-size: 16px;
      }
    }
    .server-button {
      margin-top: 32px;
      background: $gray-0;
      padding: 13px 24px 13px 24px;
      border-radius: 4px;
      border: 1px $gray-300 solid;
      justify-content: center;
      align-items: center;
      display: flex;
      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      .button-text {
        margin: 0;
        color: $business-500;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }
}
