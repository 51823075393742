@import "../../../styles/custom";
.filter {
  padding: 20px;
  width: 320px;
  border: 1px solid $gray-200;
  border-radius: 8px;
  background-color: $gray-0;

  &__dropdown {
    position: relative;

    &__header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      padding: 8px;
      border: 1px solid $gray-300;
      border-radius: 4px;

      &.disabled {
        cursor: not-allowed;
        color: $gray-300;
      }
      span {
        max-width: 220px;
        flex-grow: 1;
      }
    }

    &__content {
      position: absolute;
      padding-inline-start: 8px;
      padding-inline-end: 8px;
      padding-top: 12px;
      padding-bottom: 12px;
      top: 55px;
      width: 100%;
      border-radius: 4px;
      overflow-y: auto; // Optional: add scroll if content exceeds max-height
      background-color: #fff;
      border: 1px solid #ccc;
      z-index: 99;

      div[data-component="Box"] {
        width: 100% !important;
      }

      &__beneficiary-label {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        &--disabled {
          color: $gray-600;
        }
      }

      &__beneficiary-value {
        flex-grow: 1;
        margin-inline-end: 16px;
      }

      &__beneficiary-count {
        margin-inline-start: auto;
      }
    }
  }

  &__title {
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 24px;
    border-bottom: 1px solid $gray-300;
  }

  &__section {
    margin-top: 24px;
    margin-bottom: 20px;
  }

  &__label {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  &__button {
    width: 100% !important;
    p {
      display: inline-block !important;
    }
  }

  form {
    .form-check {
      margin-bottom: 10px;
    }

    .form-select {
      width: 100%;
      padding: 10px;
      border-radius: 4px;
      font-size: 14px;
      border: 1px solid #ced4da;
    }
  }
}