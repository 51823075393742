@import "../../../styles/custom";

.navigation-search {
  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: center;
  }

  &__open-popup-btn {
    background-color: transparent;
    border: none;
    border-bottom: 3px solid transparent;
    padding: 0;

    &:focus-visible {
      border-bottom: 0px solid transparent;
      @include focus();
    }

    &.nav-link--with-underline {
      @include media-breakpoint-up(lg) {
        &:hover {
          border-bottom: 3px solid white;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      height: 24px;

      &:focus {
        box-shadow: none;
      }
    }

    .search-icon {
      &.icon {
        margin-inline-end: 0;

        @include media-breakpoint-up(lg) {
          margin-inline-end: 8px;
        }
      }

      &--light {
        color: $business-500;
      }
    }

    span {
      display: none;

      @include media-breakpoint-up(lg) {
        display: inline;
      }
    }
  }

  .search-popup {
    position: fixed;
    top: 62px;
    inset-inline-end: 100%;
    width: 0;
    height: 100vw;
    min-height: 100%;
    background-color: $gray-50;
    border-top: 1px solid $gray-200;
    z-index: 1000;
    transition: width 0.25s ease-in;
    overflow-x: hidden;
    display: none;

    &--open {
      inset-inline-end: 0;
      display: block;

      @include media-breakpoint-down(lg) {
        width: 100vw;
        transition: width 0.25s ease-in;
      }

      @include media-breakpoint-up(lg) {
        border-top: none;
        background-color: white;
        top: 0;
        width: 100vw;
        transition: unset;
      }
    }
    &__category-title {
      color: $gray-600;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      letter-spacing: 0.4px;
      text-transform: uppercase;
    }
    &__category-container{
      margin-bottom: 24px;
    }
    &__container {
      max-width: 744px;
      width: 100%;
      margin: 0 auto;
      position: relative;
      padding: 0 16px;

      @include media-breakpoint-up(lg) {
        padding: 0;
      }
    }

    &__hero {
      padding-top: 16px;
      background-color: $gray-50;
      z-index: 999;
      position: relative;

      @include media-breakpoint-up(lg) {
        height: 239px;
        padding-top: 56px;
        background-color: $business-800;
        color: white;
      }
    }

    &__close-popup-btn {
      position: absolute;
      inset-inline-end: 16px;
      background-color: $gray-50;
      border: none;
      color: white;
      height: 32px;
      padding: 0;
      align-items: center;
      justify-content: flex-end;
      z-index: 999;

      &:focus-visible {
        @include focus();
      }

      &--desktop {
        display: none;
        top: -32px;
        inset-inline-end: -64px;
        background-color: transparent;
        justify-content: center;

        @include media-breakpoint-up(lg) {
          display: flex;
        }
      }

      &--mobile {
        display: flex;
        top: -15px;
        inset-inline-end: -50px;

        @include media-breakpoint-up(lg) {
          display: none;
        }
      }

      .close-icon {
        height: 24px;
        width: 24px;

        @include media-breakpoint-up(lg) {
          height: 32px;
          width: 32px;
        }
      }
    }

    &__title {
      font-size: 36px;
      font-weight: 600;
      margin-bottom: 32px;
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    &__content {
      padding-top: 32px;
      background-color: $gray-50;

      @include media-breakpoint-up(lg) {
        padding-top: 56px;
        background-color: white;
      }
    }

    &__content-subtitle {
      font-size: 20px;
      font-weight: 600;
      margin: 0;
      margin-bottom: 24px;
    }

    &__popular-list {
      margin: 0;
      list-style: none;
      padding: 0;

      li {
        font-size: 16px;
        font-weight: 500;
        margin-top: 8px;
      }
    }
  }
}
