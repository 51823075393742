@import "../../../../../styles/custom";
.accordion {
  width: 100%;
  height: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  background: $gray-50;
  justify-content: flex-start;
  align-items: center;
  display: inline-flex;

  
  &__copy-button{
    .copy-icon {
      visibility: hidden; 
      @include media-breakpoint-down(lg) {
        visibility: visible; 
      }
    }
    
  
    &:hover {
      .copy-icon {
        visibility: visible; 
      }
    }
  }
  
  &.open { 
    position: relative;
    width: calc(100% + 32px);
    left: -16px;
    padding-right: 16px;
    padding-left: 16px;
    border: 4px solid $warning-500;
    box-shadow: inset 0 0 0 4px $gray-900;
  }

  @at-root body.arabic {
    .accordion.open { 
      right: -16px;
      left: auto;
    }
  }
  &__content {
    padding-top: 16px;
    display: block;

    &__copy{
      display: flex;
    }
    &__text{
      margin-right: 4px;
      font-weight: 600;
    }
  }

  &__header {
    flex: 1 1 0;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    display: flex;
  }
  &__title {
    flex: 1 1 0;
    color: $gray-900;
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
    word-wrap: break-word;
  }

  &__icon {
    position: relative;
  }
}
.accordion-body{
  @include media-breakpoint-down(md) {
    background-color: $gray-50;
  }
}
.accordion__content .icon-tooltip-container{
  position: relative;
  display: inline-block;
}