@import "../../../../styles/custom";

.not-found-main {
  background-color: $gray-50 !important;
  background: url("../../../../../public/img/big-grey-logo.png") no-repeat right
    9px;
  background-size: contain;
  padding-left: 156px;
  direction: ltr;

  @include media-breakpoint-down(md) {
    background: none;
    padding-left: 16px;
    padding-right: 16px;
  }
  &.not-found-main--rtl {
    background: url("../../../../../public/img/big-grey-logo.png") no-repeat
      left 9px;
    background-size: contain;
    direction: rtl;
    padding-right: 156px;
    padding-left: 0px;
    @include media-breakpoint-down(md) {
      background: none;
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .not-found-content {
    padding-top: 40px;
    padding-bottom: 80px;

    .not-found-header {
      font-size: 48px;
      font-weight: 600;
      color: $gray-900;
      line-height: 130%;
      @include media-breakpoint-down(md) {
        font-size: 36px;
      }
    }
    .not-found-error-code {
      font-size: 20px;
      font-weight: 400;
      color: $gray-700;
      line-height: 140%;
      @include media-breakpoint-down(md) {
        font-size: 16px;
      }
    }
    .not-found-message {
      font-size: 20px;
      font-weight: 400;
      color: $gray-900;
      line-height: 140%;
      @include media-breakpoint-down(md) {
        font-size: 16px;
      }
    }
    .not-found-try-ways {
      font-size: 16px;
      font-weight: 400;
      color: $gray-900;
      line-height: 150%;
    }
  }

  ul {
    list-style: none;
    font-size: 16px;
    font-weight: 400;
    color: $gray-900;
    line-height: 150%;
  }

  ul li::before {
    content: "\2022";
    color: $business-600;
    font-weight: bold;
    display: inline-block;
    width: 24px;
    padding-bottom: 8px;
  }
}

.not-found-search {
  background-color: $gray-0 !important;
  padding-top: 56px;
  padding-bottom: 120px;
  padding-left: 156px;
  padding-right: 156px;
  direction: ltr;

  &.not-found-search--rtl {
    background-color: $gray-0 !important;
    padding-top: 56px;
    padding-bottom: 120px;
    padding-left: 156px;
    padding-right: 156px;
    direction: rtl;
    @include media-breakpoint-down(md) {
      padding-left: 16px;
      padding-right: 16px;
    }
    .not-found-search-component {
      padding-right: 0px;
      margin-bottom: 40px;
    }
  }

  .not-found-search-component {
    padding-left: 0px;
    margin-bottom: 40px;
  }

  @include media-breakpoint-down(md) {
    background: none;
    padding-left: 16px;
    padding-right: 16px;
  }
  .not-found-search-title {
    font-size: 16px;
    font-weight: 400;
    color: $gray-900;
    line-height: 150%;
    @include media-breakpoint-down(md) {
      font-size: 20px;
    }
  }
  .not-found-search-header {
    font-size: 20px;
    font-weight: 600;
    color: $gray-900;
    line-height: 140%;
    margin-bottom: 24px;
  }
  a {
    color: $business-500;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 16px;
    text-decoration: none;
    &:hover {
      color: $business-600;
    }
  }
}

.not-found-search .hero-search-input__input-wrapper {
  border-radius: 4px;
  border: 1px solid $gray-300;
  padding: 16px 12px 16px 12px;
  margin-right: 4px;
  @include media-breakpoint-down(lg) {
    border: none;
    padding: 0px;
  }
}
.not-found-search .hero-search-input__wrapper {
  padding: 16px 12px 16px 12px;
  margin-right: 4px;
}
.not-found-search--rtl .hero-search-input__wrapper {
  padding: 16px 12px 16px 12px;
  margin-right: 0px;
  margin-left: 4px;
}

.not-found-search--rtl .hero-search-input__input-wrapper {
  margin-left: 4px;
  margin-right: 0px;
}
.not-found-search--rtl .hero-search-input__search-btn {
  padding-right: 36px;
  padding-left: 36px;
}
