@import "../../styles/custom";

.exploring-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 72px;
  margin-top: 72px;

  @include media-breakpoint-up(md) {
    margin-top: 0;
  }

  &__card {
    display: flex;
    width: 100%;
    padding: 63px 0 0 0;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    background-color: $business-800;

    @include media-breakpoint-up(md) {
      border-radius: 16px;
      gap: 71px;
      padding: 80px 0 100px 0;
      width: 1128px;
      background-image: url("../../../public/img/banner-dots-backgound-detalized.svg");
      background-repeat: no-repeat;
      background-position: 100%;
    }

    &__title-wrapper {
      padding: 0 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @include media-breakpoint-up(md) {
        align-items: center;
        max-width: 702px;
        padding: 0;
      }

      p {
        text-align: start;
        font-size: 14px;
        font-weight: 700;
        line-height: 150%;
        letter-spacing: 0.6px;
        text-transform: uppercase;
        color: $business-300;
        margin-bottom: 12px;
        @include media-breakpoint-up(md) {
          text-align: center;
        }
      }

      h2 {
        text-align: start;
        font-size: 32px;
        font-weight: 600;
        line-height: 130%;
        letter-spacing: -0.2px;
        color: #fff;
        @include media-breakpoint-up(md) {
          text-align: center;
          font-size: 36px;
        }
      }
    }
    &__action-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 482px;
      padding: 0 16px;

      p {
        text-align: start;
        font-size: 16px;
        line-height: 150%;
        color: $gray-50;
        margin-bottom: 32px;
        @include media-breakpoint-up(md) {
          text-align: center;
          margin-bottom: 48px;
          padding: 0;
        }
      }
    }
    &__button-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 12px;
      margin-bottom: 40px;

      @include media-breakpoint-up(md) {
        width: fit-content;
        flex-direction: row;
        margin-bottom: 0;
      }
      &__blue-button {
        width: 100%;
        display: flex;
        justify-content: center;
        background: $business-500;
        color: white;
        border: 0;
        border-radius: 4px;
        padding: 12px 24px;
        text-decoration: none;

        @include media-breakpoint-up(md) {
          width: fit-content;
        }

        &:hover {
          background-color: $business-600;
          color: $white;
        }

        &:active {
          background-color: $business-700;
          color: $white;
        }

        &:focus {
          &:not(:focus-visible) {
            box-shadow: none;
          }

          &:focus-visible {
            @include focus();
          }
        }
      }
      &__green-button {
        width: 100%;
        display: flex;
        justify-content: center;
        background: $individuals-500;
        color: white;
        border: 0;
        border-radius: 4px;
        padding: 12px 24px;
        text-decoration: none;

        @include media-breakpoint-up(md) {
          width: fit-content;
        }

        &:hover {
          background-color: $individuals-600;
          color: $white;
        }

        &:active {
          background-color: $individuals-900;
          color: $white;
        }

        &:focus {
          &:not(:focus-visible) {
            box-shadow: none;
          }

          &:focus-visible {
            @include focus();
          }
        }
      }
    }
    &__mobile-background {
      width: 100%;
      display: block;
      img {
        width: 100%;
      }
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }
}
