@import "../styles/custom";

.cookie-consent {
  position: fixed;
  bottom: 58px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 316px); 
  padding: 32px 40px;
  background-color: white;
  display: flex;
  flex-direction: column;
  z-index: 100000;
  border-radius: 12px;
  background: $gray-0;
  box-shadow: 0px 8px 24px 0px $gray-900;

  &.cookie-consent--rtl {
    direction: rtl;
  }

  @include media-breakpoint-down(md) {
    padding: 24px 16px 32px;
    left: 0;
    transform: none;
    width: 100%;
    bottom: -12px;
    &__button {
      width: 100%;
    }
    &__paragraph {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }

  &__header {
    font-size: 20px;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
  &__content {
    flex: 1;
  }
  &__paraghraph {
    color: $gray-900;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
  }
  &__button {
    margin-top: 16px;
    margin-bottom: 16px;
    
  }
  a {
    color: $business-500;
    font-weight: 500;
    line-height: 150%;
    text-decoration: underline;
  }
}
