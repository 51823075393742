@import "../../styles/custom";

.nav-card {
  border-radius: 4px;
  border-top: 3px solid transparent;
  text-decoration: none;
  padding: 40px 24px 24px;
  background-color: $white;
  height: 100%;
  box-shadow: $shadow-light;

  & &__icon-container {
    width: 44px;
    height: 44px;
    border-radius: 4px;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
  }

  & &__title {
    color: $gray-900;
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 600;
    line-height: 140%;
  }

  & &__description {
    color: $gray-800;
  }

  &--business {
    border-color: $business-500;

    .nav-card {
      &__icon-container {
        background-color: $business-500;
      }
    }

    &:hover,
    &:active {
      background-color: $business-100;
      border-top: 3px solid $business-600;
      transition: all 0.1s ease-out;
    }

    &:focus {
      &:not(:focus-visible) {
        box-shadow: none;
      }

      &:focus-visible {
        @include focus();
      }
    }
  }

  &--individuals {
    border-color: $individuals-500;

    .nav-card {
      &__icon-container {
        background-color: $individuals-500;
      }
    }

    &:hover,
    &:active {
      background-color: $individuals-100;
      border-top: 3px solid $individuals-600;
      transition: all 0.1s ease-out;
    }

    &:focus {
      &:not(:focus-visible) {
        box-shadow: none;
      }

      &:focus-visible {
        @include focus();
      }
    }
  }

  &--providers {
    border-color: $individuals-400;

    .nav-card {
      &__icon-container {
        background-color: $individuals-400;
        .icon{
          color: $business-700;
        }
      }
    }

    &:hover,
    &:active {
      background-color: $individuals-100;
      border-top: 3px solid $individuals-600;
      transition: all 0.1s ease-out;
    }

    &:focus {
      &:not(:focus-visible) {
        box-shadow: none;
      }

      &:focus-visible {
        @include focus();
      }
    }
  }
}
