@import "../../styles/custom";

.icon-cards-group {
  padding: 3.5rem 0 2rem;
  color: $gray-900;
  background-color: $white;

  @include media-breakpoint-up(md) {
    padding: 2.5rem 0 4rem;
  }
  @include media-breakpoint-up(lg) {
    padding: 4rem 0 5rem;
  }

  .row {
    align-items: stretch;

    @include media-breakpoint-only(md) {
      --bs-gutter-x: 30px;
    }

    .col {
      &:first-child {
        padding-top: 0;
      }

      &:nth-child(-n + 2) {
        @include media-breakpoint-up(md) {
          .icon-card {
            padding-top: 0;
          }
        }
      }

      &:nth-last-child(-n + 2) {
        @include media-breakpoint-only(md) {
          .icon-card {
            padding-bottom: 0;

            &::after {
              display: none;
            }
          }
        }
      }

      @include media-breakpoint-down(md) {
        &:last-child {
          .icon-card {
            &::after {
              display: none;
            }
          }
        }
      }

      @include media-breakpoint-up(xl) {
        &:last-child {
          .icon-card {
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }

  h3 {
    font-size: 32px;
    font-weight: 600;
    line-height: 130%;
    margin-bottom: 40px;

    @include media-breakpoint-up(md) {
      margin-bottom: 36px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 36px;
      margin-bottom: 56px;
    }
    span {
      font-weight: 600;
      color: $business-500;
      @include media-breakpoint-down(sm) {
        &::after {
          content: "\A";
          white-space: pre;
        }
      }
    }
  }
  h3 + &__subtitle {
    margin-bottom: 8px;
    margin-top: -32px;
    @include media-breakpoint-up(md) {
      margin-bottom: 40px;
      margin-top: -32px;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 56px;
      margin-top: -48px;
    }
  }
  &__subtitle {
    max-width: 648px;
  }

  &--dark {
    background-color: $business-800;
    color: $white;

    @include media-breakpoint-up(md) {
      padding: 2.5rem 0 4rem;
    }
    @include media-breakpoint-up(lg) {
      padding: 4rem 0 5rem;
    }

    .icon-card {
      &.whyUsing{
        ::after {
          background-color: $business-600;
        }
      }
      &::after {
        background-color: $business-600;
      }

      &__icon-container {
        background-color: $business-700 !important;
      }
    }

    h3 {
      @include media-breakpoint-up(lg) {
        font-size: 36px;
      }

      span {
        color: $business-300;
      }
    }
  }
}
