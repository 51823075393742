@import "../../../../../styles/custom";

.article {
  .container{
    margin-inline-start: 0;
  }
  &__title {
    margin-bottom: 16px;
  }
  &__content {
    margin-bottom: 56px;
  }
  @include media-breakpoint-down(lg) {
    .article__content {
      margin-bottom: 32px;
    }
    .article__content + .information {
      margin-top: -16px !important;
    }
  }
  &__content + .learn-more {
    margin-top: -24px;
    @include media-breakpoint-down(lg) {
      margin-top: 0;
    }
  }
  .information + .learn-more{
    @include media-breakpoint-down(lg) {
      margin-top: 8px;
    }
  }
  .article__content + .video-inside {
    margin-top: -72px;
    
  }

  .article__content + .information {
    margin-top: -40px;
    
  }

  @include media-breakpoint-down(lg) {
    .article__content + .video-inside {
      margin-top: -48px;
    }
  }
  display: flex;
  position: relative;
  @at-root {
    .article--rtl {
      * {
        direction: rtl;
      }
      ol ul {
        li {
          padding-right: 0px;
        }
        li::before {
          content: "";
          width: 0;
          height: 0;
        }
      }
      ul li {
        margin-bottom: 8px;
      }
      ul li::marker {
        margin-left: 12px;
        margin-right: 0px;
        color: $business-600;
      }
      ol {
        padding-left: 0;
        padding-right: 0;
        list-style: none;
        right: 0;
        left: auto;
      }
      ol[start] {
        counter-reset: list-item attr(start) - 1;
      }
      ol li {
        left: 0;
        margin-bottom: 8px;
        position: relative;
        padding-left: 0px;
        padding-right: 32px;
        ol li {
          list-style: lower-alpha;
          padding-left: 0px;
          ul li {
            list-style: circle;
          }
        }
        ol li::before {
          content: "";
          width: 0;
          height: 0;
        }
      }

      ol li::before {
        content: counter(list-item);
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        width: 20px;
        height: 20px;
        color: $gray-0;
        background-color: $business-600;
        border-radius: 50%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 0;
        left: auto;
      }
    }
  }
  a {
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-decoration-line: underline;
    color: $business-500;
  }
  ol ul {
    list-style: circle;
    li {
      padding-left: 0px;
    }
    li::before {
      content: "";
      width: 0;
      height: 0;
    }
  }
  ul li {
    margin-bottom: 8px;
  }
  ul li::marker {
    margin-right: 12px;
    color: $business-600;
  }
  ol {
    padding-left: 0;
    list-style: none;
    left: 0;
  }
  ol[start] {
    counter-reset: list-item attr(start) - 1;
  }
  ol li {
    left: 0;
    margin-bottom: 8px;
    position: relative;
    padding-left: 32px;
    ol li {
      list-style: lower-alpha;
      padding-left: 0px;
      ul li {
        list-style: circle;
      }
    }
    ol li::before {
      content: "";
      width: 0;
      height: 0;
    }
  }

  ol li::before {
    content: counter(list-item);
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    width: 20px;
    height: 20px;
    color: $gray-0;
    background-color: $business-600;
    border-radius: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
  }

  &__line {
    position: relative;
    width: 2px;
    height: 102%;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 16px;
      transform: translateX(-50%);
      width: 100%;
      height: calc(100% - 16px);
      background-color: $gray-900;
      opacity: 0.1;
    }

    &.article__line--rtl {
      direction: rtl;
      width: 2px;
      height: 100%;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0px;
        right: 16px;
        transform: translateX(-50%);
        width: 100%;
        height: calc(100%);
        background-color: $gray-900;
        opacity: 0.1;
      }
    }
  }
  @include media-breakpoint-down(md) {

    .article__line {
      display: none;
    }

    &__col-12 {
      padding: 0;
    }
  }
}
