@import "../../../styles/custom";

.result-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  border: 1px solid $gray-200;
  border-right-width: 0;
  border-left-width: 0;
  width: 100%;
  min-width: 100vw;
  padding: 32px 16px;
  position: relative;
  margin-bottom: 16px;

  &--rtl {
    .result-content__rate-box__info__level__icon {
      margin-left: 12px;
      margin-right: unset;
    }

    .result-content__info-box__icon {
      margin-left: 12px;
      margin-right: unset;
    }

    .result-content__level-box__item__level__icon {
      margin-left: 12px;
      margin-right: unset;
    }
  }

  &__title {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    color: $gray-900;
    margin-bottom: 24px;
  }

  &__rate-box {
    width: 476px;
    border: 1px solid $gray-300;
    border-radius: 4px;
    padding: 24px 24px 0 24px;
    margin-bottom: 16px;

    @include media-breakpoint-down(lg) {
      width: 100%;
    }

    &__line {
      display: flex;
      width: 367px;
      height: 8px;
      margin: 0 auto 24px;

      @include media-breakpoint-down(md) {
        width: 80%;
      }

      &__item {
        width: 70px;
        height: 8px;
        margin-right: 4px;
        background-color: $gray-100;
      }

      &__item.red {
        background-color: $nitaqat-red;
      }

      &__item.low-green {
        background-color: $nitaqat-low-green;
      }

      &__item.medium-green {
        background-color: $nitaqat-mid-green;
      }

      &__item.high-green {
        background-color: $nitaqat-high-green;
      }

      &__item.platinum {
        background-color: $nitaqat-platinum;
      }

      &__item:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }

    &__info:last-child {
      border-bottom: none;
    }

    &__info.p2 {
      padding: 16px 0;
    }

    &__info.p3 {
      padding: 24px 0;
      gap: 32px;
      align-items: start;

      .result-content__rate-box__info__quote {
        font-weight: 600;
      }
    }

    &__info.p4 {
      padding: 32px 0;
    }

    &__info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $gray-300;

      &__text {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }

      &__percent {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
      }

      &__level {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__icon {
          width: 12px;
          height: 12px;
          border-radius: 4px;
          margin-right: 12px;

          &.red {
            background-color: $nitaqat-red;
          }

          &.low-green {
            background-color: $nitaqat-low-green;
          }

          &.medium-green {
            background-color: $nitaqat-mid-green;
          }

          &.high-green {
            background-color: $nitaqat-high-green;
          }

          &.platinum {
            background-color: $nitaqat-platinum;
          }
        }

        &__text {
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
        }
      }

      &__text-container {
        display: flex;
        flex-direction: column;
        width: 294px;
      }
    }
  }

  &__note--text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  &__level-title {
    display: inline-block;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin-bottom: 16px;
  }

  &__level-box {
    width: 476px;
    border: 1px solid $gray-300;
    border-bottom: none;
    border-radius: 4px;
    margin-bottom: 8px;

    @include media-breakpoint-down(lg) {
      width: 100%;
    }

    &__item {
      width: 100%;
      height: 45px;
      display: flex;
      justify-content: space-between;
      padding: 12px 16px;
      border-bottom: 1px solid $gray-300;

      &.current {
        background-color: $business-600;
      }

      &__text {
        font-size: 14px;
        font-style: normal;
        line-height: 150%;
        color: $gray-700;
        font-weight: 400;

        &.light {
          color: $white;
          font-weight: 600;
        }
      }

      &__level {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__icon {
          width: 12px;
          height: 12px;
          border-radius: 4px;
          margin-right: 12px;
        }

        &__icon.red {
          background-color: $nitaqat-red;
        }

        &__icon.low-green {
          background-color: $nitaqat-low-green;
        }

        &__icon.medium-green {
          background-color: $nitaqat-mid-green;
        }

        &__icon.high-green {
          background-color: $nitaqat-high-green;
        }

        &__icon.platinum {
          background-color: $nitaqat-platinum;
        }

        &__text {
          font-size: 14px;
          font-style: normal;
          line-height: 150%;

          color: $gray-900;
          font-weight: 400;
        }

        &__text.light {
          color: $white;
          font-weight: 600;
        }
      }
    }
  }

  &__general-info-box {
    width: 476px;
    border: 1px solid $gray-300;
    border-bottom: none;
    border-radius: 4px;

    @include media-breakpoint-down(lg) {
      width: 100%;
    }

    &__item {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      min-height: 45px;
      border-bottom: 1px solid $gray-300;
      padding: 12px 16px;

      &__label {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        color: $gray-900;
      }

      &__value {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        text-align: end;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    border-radius: 8px;
    border-right-width: 1px;
    border-left-width: 1px;
    padding: 40px 32px;
    min-width: auto;
    inset-inline-start: 0;
  }
}
