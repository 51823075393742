@import "../../../../../../styles/custom";

.videos {
  @include media-breakpoint-down(lg) {
    margin-top: 32px;
  }

  &__image-container{
    cursor: pointer;
  }
  div[data-component="Image"] {
    &::before{
      background-image: none !important;
    }
  }

  div[data-component="Video"] {
    max-width: 100vw;
    @include media-breakpoint-down(lg) {
      margin-bottom: 12px;
    }
    div[data-component="Box"] {
      div[data-component="Box"] {
        button {
          visibility: hidden;
        }
      }
    }
  }
  div[data-component="Box"] {
    button {
      background-color: white;
      margin-inline-end: 12px;
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }
  }
  .videos-second {
    height: 72px;
    align-items: center;
    padding: 12px 24px 12px 24px;
    cursor: pointer;
  }

  &__help{
    font-size: 14px;
    margin-top: 32px;
  }
  &__video-group{
    margin-bottom: 32px;
  }
  &__watch-video {
    color: $gray-0;
    font-size: 20px;
    font-weight: 400;
    line-height: 140%;
    margin-inline-end: auto;
    background-color: transparent !important;
    width: fit-content !important;
    height: 100% !important;
    @include media-breakpoint-down(lg) {
      font-size: 14px;
      font-weight: 500;
      line-height: 150%;
    }
  }
  &__duration {
    color: $gray-0;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    margin-inline-start: auto;
    @include media-breakpoint-down(lg) {
      font-size: 14px;
    }
  }
  @include media-breakpoint-down(xl) {
    div[data-component="Modal"] {
      div[data-component="Box"] {
        div[data-component="VideoPlayer"] {
          max-width: 100%;
        }
      }
      button {
        width: 100%;
      }
    }
  }
  
}
