@import "../../styles/custom";

.main-menu-navbar {
  &--desktop {
    display: none;
    position: relative;
    z-index: 101;

    @media (min-width: 1279px) {
      display: flex;
    }

    &__dark {
      background-color: $business-800;
    }

    &__light {
      background-color: $white;
      color: $gray-900;

      .main-menu-navbar__left-container {
        .nav-item {
          &.dropdown {
            .nav-link {
              &:hover {
                border-bottom: 3px solid $business-500 !important;
              }
            }
          }
        }
      }

      .navbar-nav {
        .nav-link {
          &.navigation-search__open-popup-btn.nav-link--with-underline {
            &:hover {
              border-bottom: 3px solid $business-500 !important;
            }
          }
        }
      }

      .main-menu-login.nav-item {
        .nav-link.kc-link.nav-link--with-underline {
          &:hover {
            border-bottom: 3px solid $business-500 !important;
          }
        }
      }

      .language-switcher.dropdown {
        .nav-link {
          &:hover {
            border-bottom: 3px solid $business-500 !important;
          }
        }
      }
    }

    &.navbar {
      padding: 0 3rem;
      height: 104px;

      @at-root body.body-zoomed-in {
        .main-menu-navbar--desktop.navbar {
          padding: 0 0.75rem;

          .navbar-brand {
            margin-inline-end: 22px;
          }

          .main-menu-navbar__left-container {
            gap: 12px;
          }
        }
      }
    }

    .nav-item {
      height: 100%;
      display: flex;
      align-items: center;

      .dropdown-item {
        white-space: normal;
      }

      .nav-link {
        border-bottom: 3px solid transparent;

        &:focus {
          box-shadow: none;
        }
      }
    }

    .navbar-brand {
      padding: 0;
      margin-inline-end: 44px;

      .kc-link {
        &:focus {
          &:not(:focus-visible) {
            box-shadow: none;
          }

          &:focus-visible {
            @include focus();
          }
        }
      }
    }

    .navbar-nav .nav-link {
      font-size: 1rem;
      font-weight: 500;
      line-height: 150%;
      color: $white;
      height: 100%;
      display: flex;
      align-items: center;

      &.kc-link {
        height: auto;
        display: inline-block;
        border-bottom: none;
        margin-bottom: 3px;

        &:focus {
          &:focus-visible {
            color: black;
          }
        }

        &.nav-link--with-underline {
          @include media-breakpoint-up(lg) {
            height: 100%;
            display: flex;
            border-bottom: 3px solid transparent;
            margin-bottom: 0;

            &:hover {
              border-bottom: 3px solid white;
            }
          }
          &__no-hover {
            .navigation-item{
              &:hover {
                padding-bottom: -24px;
                border-bottom: 3px solid white;
              }
            
            }
            &:hover {
              border-bottom: 3px solid transparent !important;
            }
          }
        }
      }

      &.navigation-search__open-popup-btn.nav-link--with-underline,
      &.main-menu-login.nav-item .nav-link.kc-link.nav-link--with-underline {
        display: flex;
        align-items: center;

        &.nav-link--link-container {
          height: 100%;
          &:focus {
            background-color: transparent;
          }
        }

        &.kc-link--text-link {
          background-color: transparent;

          &:focus {
            background-color: transparent;

            &:focus-visible {
              .main-menu-navbar__dropdown-label {
                background-color: $warning-500;
                color: $gray-900;
              }
            }
          }
        }

        &:hover {
          border-bottom: 3px solid white;
        }
      }

      &.nav-link--link-container {
        &:focus {
          background-color: transparent;
        }
      }

      &__light {
        color: $gray-900;
        font-weight: 500;

        span {
          font-weight: 500;
        }
      }
    }

    .main-menu-login.nav-item .nav-link.kc-link.nav-link--with-underline {
      &.nav-link--link-container {
        height: 100%;

        &:focus {
          background-color: transparent;
        }
      }

      &.kc-link--text-link {
        background-color: transparent;

        &:focus {
          background-color: transparent;

          &:focus-visible {
            .main-menu-navbar__dropdown-label {
              background-color: $warning-500;
              color: $gray-900;
            }
          }
        }
      }

      &:hover {
        border-bottom: 3px solid white;
      }
    }

    .navbar-collapse {
      height: 100%;
    }

    .nav-link.dropdown-toggle {
      &::after {
        content: "";
        margin: 0;
        border: 0;
      }

      .chevron-down-icon {
        margin-inline-start: 4px;
        transition: all 0.2s ease-out;

        &__light {
          color: $business-500;
        }
      }
    }
  }

  &--mobile {
    display: block;
    padding-top: 10px;
    padding-bottom: 11px;
    background-color: $gray-50;

    @media (min-width: 1279px) {
      display: none;
    }
  }

  &__action-bar-logo {
    .kc-link {
      @include media-breakpoint-down(lg) {
        display: inline-block;

        &:focus-visible {
          background-color: transparent;
          @include focus();
        }
      }
    }
  }

  &--show {
    &.main-menu-navbar {
      .main-menu-navbar__burger-button--burger-icon {
        transform: rotate(-90deg);
        opacity: 0;
      }
      .main-menu-navbar__burger-button--close-icon {
        transform: rotate(-90deg);
        opacity: 1;
      }
    }
  }

  & &__left-container {
    height: 100%;
    align-items: center;
    margin-inline-end: auto;
    gap: 24px;

    .main-menu-navbar__dropdown-label__light {
      color: $gray-900;
      font-weight: 500;
    }

    .chevron-down-icon__light {
      color: $business-500;
    }

    .dropdown-menu {
      margin-block-start: 0;
      width: 100%;
      inset-inline-start: 0;
      background-color: $gray-50;

      h4 {
        color: $gray-900;
      }
    }

    .navbar-nav {
      .nav-link {
        padding: 0.5rem 0 0;
      }
    }

    .nav-item {
      .nav-link {
        padding: 0;

        &__light {
          color: $gray-900;
        }

        span {
          pointer-events: none;
        }
      }

      &.dropdown {
        position: initial;

        .nav-link {
          &:hover {
            border-bottom: 3px solid $white;
          }

          &:focus {
            outline: none;

            &:focus-visible {
              box-shadow: none;

              .main-menu-navbar__dropdown-label {
                position: relative;
                background-color: $warning-500;
                color: black;

                &::after {
                  display: block;
                  position: absolute;
                  content: "";
                  color: black;
                  background-color: black;
                  bottom: -1px;
                  left: 0;
                  width: 100%;
                  height: 2px;
                }
              }
            }
          }
        }

        &.show {
          .nav-link {
            color: $white;
            border-bottom: 3px solid $white;

            &.dropdown-toggle {
              .chevron-down-icon {
                transform: rotate(-180deg);
                transition: all 0.2s ease-out;
              }
            }
          }
        }

        .dropdown-menu {
          inset-inline-start: 0;
          border-radius: 0;
          padding: 2rem 0 1.5rem 0;
        }
      }
    }
  }

  & &__right-container {
    height: 100%;
    gap: 24px;

    &.navbar-nav {
      .nav-link {
        padding: 0;
      }
    }
  }

  & &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .icon {
      color: $business-500;
    }
  }

  & &__burger-button {
    margin-inline-start: 24px;
    height: 24px;
    width: 24px;
    border: none;
    background-color: transparent;
    padding: 0;
    position: relative;

    &--burger-icon {
      position: absolute;
      inset-block-start: 0;
      inset-inline-start: 0;
      opacity: 1;
      transform: rotate(0deg);
      transition: all 0.2s ease-in;
    }

    &--close-icon {
      position: absolute;
      inset-block-start: 0;
      inset-inline-start: 0;
      opacity: 0;
      transform: rotate(0deg);
      transition: all 0.2s ease-in;
    }

    &:focus-visible {
      @include focus();
    }
  }

  .search-icon,
  .globe-icon {
    &__light {
      color: $business-500;
    }
    margin-inline-end: 8px;
  }

  .icon {
    &__light {
      color: $business-500;
    }
  }
}

.second-level-menu {
  &.row {
    align-items: stretch;

    .dropdown-item {
      height: 100%;
      padding: 0;

      .action-tile {
        transition: none;
      }

      &:hover {
        background-color: initial;
      }

      .second-level-menu__link {
        &:focus {
          &:focus-visible {
            .action-tile {
              border-top: none;
              padding-top: 3px;
              @include focus();
            }
          }
        }
      }

      .custom-card {
        height: 100%;
      }
    }
  }

  & &__link {
    display: block;
    height: 100%;
  }
}

.language-switcher {
  &.dropdown {
    .dropdown-menu {
      width: 336px;
      transform: translateX(-100%);
      inset-inline-start: 100%;
      padding: 0.5rem;
      margin-block-start: 12px;

      @at-root body.arabic {
        .language-switcher.dropdown {
          .dropdown-menu {
            transform: translateX(100%);
            inset-inline-end: 0;
          }
        }
      }
    }

    .nav-link {
      &:hover {
        border-bottom: 3px solid $white;
      }

      .language-switcher__dropdown-label {
        &__theme {
          color: $gray-900;
        }
      }

      &:focus {
        outline: none;

        &:focus-visible {
          box-shadow: none;

          .language-switcher__dropdown-label {
            position: relative;
            background-color: $warning-500;
            color: black;

            &::after {
              display: block;
              position: absolute;
              content: "";
              color: black;
              background-color: black;
              bottom: -1px;
              left: 0;
              width: 100%;
              height: 2px;
            }
          }
        }
      }
    }

    &.show {
      .nav-link {
        color: $white;

        &.dropdown-toggle {
          border-bottom: 3px solid $white;

          .chevron-down-icon {
            transform: rotate(-180deg);
            transition: all 0.2s ease-out;
          }
        }
      }
    }
  }

  & &__option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border-radius: 4px;
    color: $gray-900;

    &__flag {
      margin-inline-end: 12px;
    }

    &:hover,
    &:active {
      background-color: $business-100;
      color: $gray-900;
    }

    &:focus {
      &:not(:focus-visible) {
        box-shadow: none;
      }

      &:focus-visible {
        @include focus();
      }
    }

    &--active {
      font-weight: 500;
    }
  }

  & &__active-icon-container {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: $business-500;
    position: relative;

    .checkmark-icon {
      position: absolute;
      inset-block-start: 50%;
      inset-inline-start: 50%;
      transform: translateX(-50%) translateY(-50%);
      color: $white;

      @at-root body.arabic {
        .language-switcher__active-icon-container {
          .checkmark-icon {
            inset-block-start: -20%;
            transform: translateX(50%) translateY(50%);
          }
        }
      }
    }
  }
}

.mobile-menu-pane {
  position: fixed;
  inset-block-start: 61px;
  inset-inline-start: 100vw;
  height: calc(var(--window-inner-height) - 62px);
  background-color: $white;
  z-index: 200;
  transition: all 0.2s ease-in;
  overflow-y: scroll;
  border-top: 1px solid $gray-200;
  padding-top: 8px;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  width: 100vw;

  &--open {
    inset-inline-start: 0;
    visibility: visible;
  }

  &__main-pane {
    height: 100%;
  }

  &__first-level-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__first-level-list-item {
    border-bottom: 1px solid $gray-100;
  }

  &__first-level-button {
    padding: 24px 0;
    border: 0;
    background-color: transparent;
    color: $gray-900;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-decoration: none;

    @include media-breakpoint-up(md) {
      padding: 24px 16px;
    }
  }

  &__actions-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 292px);
  }

  &__language {
    width: 100%;
    height: 58px;
    border-top: 1px solid $gray-200;
  }

  &__language-button {
    width: 100%;
    height: 100%;
    padding: 0;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      color: $individuals-500;
      margin-inline-end: 8px;
    }
  }
}

.secondary-mobile-pane {
  position: absolute;
  inset-block-start: -1px;
  inset-inline-start: 100%;
  height: 100%;
  width: 0;
  background-color: $white;
  z-index: 201;
  transition: all 0.2s ease-in;
  overflow-x: hidden;
  overflow-y: auto;
  border-top: 1px solid $gray-200;
  padding-top: 32px;

  &--open {
    inset-inline-start: 0;
    width: 100%;
  }

  &__title {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    line-height: 140%;
    margin-bottom: 24px;
  }

  &__back-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    padding: 0;
    color: $business-500;
    font-weight: 500;
    margin-block-end: 8px;
  }

  ul {
    list-style: none;
    margin-block-start: 8px;
    margin-block-end: 85px;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding: 0;

    li {
      border-bottom: 1px solid $gray-100;
      margin-block-end: 0;
    }
  }

  &__link-title-wrapper {
    display: flex;
    align-items: center;
  }

  &__link {
    padding: 24px 0;

    a {
      text-decoration: none;
      color: $business-500;
      font-weight: 600;

      p {
        margin: 0;
        text-decoration: none;
        color: $gray-900;
        font-weight: 400;
      }
    }
  }

  &__language-link {
    height: 56px;
    padding: 0 16px;
    margin-inline-start: -16px;
    width: calc(100% + 32px);

    &--selected {
      background-color: $business-100;
    }

    a {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      text-decoration: none;
      color: $gray-800;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;

      .icon {
        margin-inline-end: 16px;

        &.checkmark-icon {
          margin-inline-end: 0;
        }
      }
    }
  }

  &__active-language-icon-container {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: $business-500;
    position: relative;

    .checkmark-icon {
      position: absolute;
      inset-block-start: 50%;
      inset-inline-start: 50%;
      transform: translateX(-50%) translateY(-50%);
      color: $white;

      @at-root body.arabic {
        .secondary-mobile-pane__active-language-icon-container {
          .checkmark-icon {
            transform: translateX(50%) translateY(-50%);
          }
        }
      }
    }
  }
}

.main-menu-login {
  &__group{
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 5px !important;

    &__login{
      &:hover {
        border-bottom: 3px solid white;
        margin-bottom: -3px;
      }
    }
    &__register{
      &:hover {
        border-bottom: 3px solid white;
        margin-bottom: -3px;
      }
    }
    &::before{
      content: '';
      width: 1px;
      height: 24px;
      background-color: $gray-300;
    }

    &:hover {
      border-bottom: none !important
    }
  }
  &.nav-item {
    display: flex;
    align-items: center;

    .main-menu-login__desktop-link {
      height: 100%;
      margin-inline-end: 8px;
      margin-bottom: 5px;
      gap: 24px;
      button{
        background-color: transparent;
        border-color: $gray-300;
        &:focus-visible {
          background-color: $warning-500 !important;
        }
        &:hover {
          background: #FFFFFF1A;
        }
      }
      &__light {
        color: $gray-900;
        font-weight: 500;

        .main-menu-login__group__login{
          color: $gray-900;
          &:hover {
            border-bottom: 3px solid $business-500;
            margin-bottom: -3px;
          }
        }
        .main-menu-login__group__register{
          .kc-link{
            color: $gray-900 !important;
          }
          &:focus-visible {
            background-color: $individuals-500 !important;
          }
          &:hover {
            border-bottom: 3px solid $business-500;
            margin-bottom: -3px;
          }
        }
        button{
          background-color: transparent;
          border-color: $gray-300;
          &:hover {
            background-color: $gray-100;
          }
          p{
            color: $gray-900;
          }
        }
      }

      &:hover {
        border-bottom: 3px solid white;
      }

      &__flex-wrapper {
        display: flex;
        gap: 8px;
        p {
          margin-bottom: 0;
        }
      }
    }
  }

  &__mobile {
    list-style: none;
    margin: 32px 0 0;
    padding: 0 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  &__mobile-link {
    width: 100%;
    margin-block-end: 12px;

    &--login {
      a {
        background-color: $gray-0;
        color: $business-500 !important;
        border: 1px solid $gray-300;

        &:hover {
          background-color: $gray-50;
        }

        &:focus {
          &:not(:focus-visible) {
            box-shadow: none;
          }

          &:focus-visible {
            background-color: $warning-500;
            @include focus();
          }
        }
      }
    }

    &--sign-up {
      a {
        background-color: $business-500;

        &:hover {
          background-color: $business-600;
          color: $gray-0;
        }

        &:focus {
          &:not(:focus-visible) {
            box-shadow: none;
          }

          &:focus-visible {
            background-color: $warning-500;
            // box-shadow: none;
            outline: none;
            @include focus();
          }
        }
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      width: 100%;
      height: 48px;
      border-radius: 4px;
      color: $white;
    }

    @include media-breakpoint-up(md) {
      width: calc(50% - 6px);
      margin-block-end: 0;
    }
  }
}
.sticky-header {
  position: sticky;
  top: -200px;
  width: 100%;
  z-index: 101;
}

.sticky-header-visible {
  top: 0;
}
