@import "../../styles/custom";

.contrast-mode-dropdown {
  width: auto;

  @include media-breakpoint-up(md) {
    position: relative;
  }

  &__trigger-list-btn {
    background-color: transparent;
    border: none;
    padding: 0;
    color: $business-500;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    gap: 2px;

    .icon {
      width: 20px;
      height: 20px;
    }

    text-decoration: none;
    text-underline-offset: 2px;

    @include media-breakpoint-up(md) {
      text-decoration: underline;
    }

    &:focus-visible {
      @include focus();
    }
  }

  &__modes-list {
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    width: calc(100vw - 32px);
    display: none;
    list-style: none;
    margin: 0;
    padding: 8px;
    background-color: white;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.12),
      0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 100;

    @include media-breakpoint-up(md) {
      width: 210px;
      inset-inline-start: unset;
      inset-inline-end: 0;
    }

    &--open {
      display: block;
    }
  }

  &__modes-list-item {
    width: 100%;
  }

  &__mode-btn {
    width: 100%;
    background-color: transparent;
    border: none;
    padding: 10px 12px;
    display: flex;
    align-items: center;
    gap: 12px;
    color: $gray-900;

    &:hover,
    &--selected {
      background-color: $business-100;
      color: $business-500;

      .icon {
        color: $business-500;
      }
    }

    &:focus {
      outline: none;

      &:focus-visible {
        box-shadow: $focus-outline;
      }
    }

    .icon {
      color: $gray-600;
    }
  }
}
