@import "../../styles/custom";

.home-page-video-banner {
  background-color: $business-800;
  padding-top: 80px;
  padding-bottom: 120px;

  @include media-breakpoint-down(lg) {
    padding-top: 56px;
    padding-bottom: 64px;
  }

  &__left-col {
    margin-inline-end: auto;
    padding-inline-end: 0;
    width: 42%;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }

  &__header {
    color: $gray-0;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: -0.2px;
    margin-bottom: 16px;
    @include media-breakpoint-down(lg) {
      margin-bottom: 8px;
      font-size: 32px;
    }
    span {
      color: $business-300;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
      letter-spacing: -0.2px;
      @include media-breakpoint-down(lg) {
        font-size: 32px;
      }
    }
  }
  &__content {
    color: $gray-0;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    @include media-breakpoint-down(lg) {
      margin-bottom: 32px;
    }
  }
  &__button {
    margin-top: 24px;
    padding: 11px 24px 13px 24px;
    display: inline-flex;
    align-items: center;
    &:hover {
      border-color: $business-800;
    }
    @include media-breakpoint-down(lg) {
      margin-top: 12px;
    }

    &__full-width {
      justify-content: center;
      align-items: center;
      display: inline-flex;
      width: 100%;
      margin-top: 12px;
    }
  }
  &__icon-container {
    align-items: center;
    align-content: center;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: $gray-0;
    margin-inline-end: 8px;
  }
  &__icon {
    color: $business-500;
  }
  &__play-video-img {
    position: absolute;
    width: 48px;
    height: 48px;
    border-radius: 50% !important;
    &:hover {
      border-color: $business-600;
    }
    padding: 0;
  }
  &__image {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @include media-breakpoint-down(xl) {
    div[data-component="Modal"] {
      div[data-component="Box"] {
        div[data-component="Video"] {
          max-width: 100%;
        }
      }
      button {
        width: 100%;
      }
    }
  }
  
}

