@import "../../styles/custom";

.banner-component {
  background-color: $business-800;
  color: $white;

  &--hero-variant {
    padding: 40px 0;
    padding-bottom: 0;

    @include media-breakpoint-up(lg) {
      padding: 72px 96px;
      padding-bottom: 0;
      background-size: auto;
    }
  }

  &__main-title {
    padding: 0;
    margin: 0 auto 24px;
    max-width: 936px;
    align-self: center;

    @include media-breakpoint-up(lg) {
      padding: 0 40px;
    }

    h2 {
      font-size: 2rem;
      font-weight: 600;
      line-height: 130%;
      margin-bottom: 0;

      @include media-breakpoint-up(lg) {
        font-size: 3rem;
        text-align: center;
      }
    }

    span {
      color: $business-300;
      font-weight: 600;
      display: block;

      @include media-breakpoint-up(lg) {
        display: inline;
      }
    }
  }

  &__sub-title {
    display: flex;
    margin-bottom: 32px;

    @include media-breakpoint-up(lg) {
      justify-content: center;
      margin-bottom: 40px;
    }

    p {
      max-width: 744px;
      margin: 0;
      font-size: 16px;
      line-height: 140%;
      font-weight: 400;
      color: $gray-50;
      text-align: left;

      @include media-breakpoint-up(lg) {
        font-size: 20px;
        text-align: center;
      }
    }
  }

  &__cta-links-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 64px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 72px;
    }
  }

  &__cta-link {
    border-radius: 4px;
    width: 100%;
    padding: 12px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    color: $white;
    text-decoration: none;

    @include media-breakpoint-up(lg) {
      width: 235px;
    }

    &:hover {
      color: $white;
    }

    &:focus {
      &:not(:focus-visible) {
        box-shadow: none;
      }

      &:focus-visible {
        @include focus();
      }
    }

    &--business {
      background-color: $business-500;

      &:hover {
        background-color: $business-600;
      }

      &:active {
        background-color: $business-800;
      }

      &:focus {
        &:not(:focus-visible) {
          box-shadow: none;
        }

        &:focus-visible {
          @include focus();
        }
      }
    }

    &--individuals {
      background-color: $individuals-500;

      &:hover {
        background-color: $individuals-600;
      }

      &:active {
        background-color: $individuals-900;
      }

      &:focus {
        &:not(:focus-visible) {
          box-shadow: none;
        }

        &:focus-visible {
          @include focus();
        }
      }
    }
  }

  &__main-image {
    width: 100%;
    display: flex;
    justify-content: center;

    &__main-image-position {
      object-fit: cover;
      object-position: left;
      height: 100%;
    }

    &__main-image-position-mobile {
      max-width: 100% !important;
    }

    @at-root {
      body[lang="ar"] {
        .banner-component__main-image__main-image-position {
          object-position: right;
        }
        @include media-breakpoint-down(md) {
          .banner-component__main-image__bottom {
            width: 100% !important;
          }
        }
      }
    }

    &--hero {
      img {
        max-width: 936px;
        max-height: 573px;

        @include media-breakpoint-down(lg) {
          max-width: calc(100%);
        }
      }
    }

    &--body {
      img {
        max-width: 1032px;
        max-height: 573px;
        position: relative;
        inset-inline-start: 403px;

        @include media-breakpoint-down(lg) {
          max-width: calc(100%);
          inset-inline-start: 0px;
        }
      }
    }
  }

  &--grey-dots {
    padding: 48px 0 72px;
    background-color: $gray-50;
    background-image: url("../../../public/img/banner-dots-background.svg");
    background-repeat: no-repeat;
    background-position: 90% 95%;
    background-size: 200%;

    @include media-breakpoint-up(md) {
      padding: 97px 0 192px;
      background-position: 79% 35%;
      background-size: 80%;
    }

    @include media-breakpoint-up(lg) {
      background-position: 79% 35%;
      background-size: 50%;
    }

    .banner-component__main-title {
      color: $gray-900;
      max-width: 702px;
      font-size: 32px;
      line-height: 130%;
      padding: 0;

      @include media-breakpoint-up(md) {
        text-align: center;
        font-size: 36px;
      }
    }

    .banner-component__sub-title {
      color: $gray-700;
      max-width: 462px;
      margin-left: auto;
      margin-right: auto;

      @include media-breakpoint-up(md) {
        text-align: center;
      }
    }

    .banner-component__cta-links-wrapper {
      margin-bottom: 48px;
    }

    .banner-component__cta-link {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: auto;
      }
    }

    .banner-component__cta-sublinks-block {
      display: flex;
      flex-wrap: wrap;

      @include media-breakpoint-up(md) {
        justify-content: center;
      }

      h6 {
        width: 100%;
        color: $gray-900;
        text-transform: uppercase;
        margin-bottom: 24px;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.6px;

        @include media-breakpoint-up(md) {
          text-align: center;
        }
      }
    }

    .banner-component__cta-sublinks-wrapper {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;

      @include media-breakpoint-up(md) {
        align-items: center;
        flex-direction: row;
      }

      a {
        margin-inline-end: 0;
        margin-block-end: 16px;

        &:last-child {
          margin-inline-end: 0;
          margin-block-end: 0;
        }

        @include media-breakpoint-up(md) {
          margin-inline-end: 16px;
          margin-block-end: 0;
        }
      }
    }
  }

  &--body-variant {
    padding: 72px 0 383px;
    background-color: $business-800;
    max-width: 100vw;
    overflow: hidden;
    position: relative;

    @include media-breakpoint-up(md) {
      padding: 90px 0;
    }

    @include media-breakpoint-up(lg) {
      padding: 155px 0 256px;
      background-image: url("../../../public/img/background-lines.svg");
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: bottom;
    }

    .banner-component__content-row {
      flex-direction: row;
      flex-wrap: nowrap;

      .banner-component__content-wrapper {
        @include media-breakpoint-only(md) {
          max-width: 50%;
        }
      }

      .banner-component__main-title {
        text-align: start;
        padding: 0;
        font-size: 32px;
        font-weight: 600;
        line-height: 130%;
        margin-bottom: 16px;

        span {
          display: inline;
        }

        @include media-breakpoint-up(md) {
          font-size: 32px;
          font-weight: 700;
          line-height: 130%;
          margin-bottom: 32px;
        }

        @include media-breakpoint-up(lg) {
          font-size: 48px;
        }
      }

      .banner-component__sub-title {
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
        margin-bottom: 32px;

        @include media-breakpoint-up(md) {
          font-size: 16px;
          line-height: 140%;
          margin-bottom: 48px;
        }

        @include media-breakpoint-up(lg) {
          font-size: 20px;
          line-height: 140%;
        }
      }

      .banner-component__main-image {
        padding: 0;
        display: block;
        width: auto;
        position: absolute;
        inset-inline-end: 0;
        bottom: 0;

        @include media-breakpoint-only(md) {
          width: 49%;
          top: 50px;
        }

        @include media-breakpoint-up(lg) {
          width: 50%;
          top: 140px;
        }

        @include media-breakpoint-up(xl) {
          width: auto;
          top: 80px;
        }
      }

      .banner-component__cta-link {
        width: auto;

        @include media-breakpoint-up(lg) {
          width: 150px;
        }
      }
    }
  }
}
