@import "../../styles/custom";

.linking-occupations {
  background-color: $gray-50;
  padding-bottom: 48px;
  position: relative;

  @include media-breakpoint-up(lg) {
    padding-bottom: 120px;
  }

  &__content {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    width: 100vw;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      flex-wrap: nowrap;
      width: auto;
    }
  }

  &__text-content {
    width: calc(100% - 32px);

    @include media-breakpoint-up(lg) {
      max-width: 50%;
      width: 100%;
    }
  }

  &__economic-activity {
    background: white;
    border: 1px solid $gray-200;
    border-right-width: 0;
    border-left-width: 0;
    width: 100%;
    padding: 32px 16px;
    position: relative;
    inset-inline-start: -16px;

    &__error-msg {
      color: $nitaqat-red;
      font-size: 14px;
    }

    &__loader {
      display: flex;
      justify-content: center;
    }

    &.height {
      height: 160px;
    }

    &--with-error.height {
      height: 205px;
    }

    &__search {
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;
      gap: 16px;
      flex-direction: column;
      max-width: calc(100vw - 32px);

      @include media-breakpoint-up(lg) {
        flex-direction: row;
        gap: 8px;
      }
    }

    &__search-select {
      margin-right: 10px;
      margin-left: 0;
    }

    &__border {
      width: 100%;
      border-top: 1px solid $gray-200;
    }

    &__filter {
      position: relative;
      margin: 24px 0;

      div[data-component="Field"] {
        input {
          padding-inline-start: 50px;
        }
      }

      .form-control {
        padding: 11px 50px;
      }

      &--icon {
        position: absolute;
        top: 11px;
        left: 16px;
        right: unset;
        z-index: 10;
      }
    }

    &__result-text {
      display: inline-block;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 24px;

      span {
        font-weight: 600;
      }

      @include media-breakpoint-down(lg) {
        font-weight: 400;
        font-size: 14px;

        span {
          font-weight: 600;
        }
      }
    }

    &__result-header {
      display: block;
    }

    &__result-list {
      list-style: none;
      position: relative;
      padding: 0;
      overflow: auto;

      .linking-occupations__economic-activity__result-list__item {
        padding-left: 21px;
        padding-right: 0;
      }

      &__data {
        overflow: auto;
        height: 480px;
      }

      &__item {
        display: flex;
        align-items: center;
        height: 48px;

        &:nth-of-type(even) {
          background-color: $gray-50;
          border-bottom: 1px solid $gray-100;
        }

        &.sticky {
          position: sticky;
          top: 0;
          background-color: $gray-50;
          border-bottom: 1px solid $gray-100;
          font-weight: 600;
        }

        mark {
          padding: 0;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      max-width: 50%;
      border-radius: 8px;
      border-right-width: 1px;
      border-left-width: 1px;
      padding: 40px 32px;
    }

    &--rtl {
      .linking-occupations__economic-activity__search-select {
        margin-left: 10px;
        margin-right: 0;
      }

      .linking-occupations__economic-activity__filter--icon {
        right: 16px;
        left: unset;
      }

      .linking-occupations__economic-activity__result-list {
        .linking-occupations__economic-activity__result-list__item {
          padding-right: 21px;
          padding-left: 0;
        }
      }
    }
  }

  &__title {
    font-size: 48px;
    font-weight: 700;
    letter-spacing: -0.5px;
    margin: 0;
    max-width: 457px;
  }

  &__description {
    font-size: 16px;
    font-weight: 400;
    margin: 16px 0 0;
    max-width: 457px;
  }
}
