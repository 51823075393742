@import "../../../styles/custom";

.saudi-labor-system-hero {
  background-color: $business-800;
  padding: 64px 156px 80px 156px;
  color: $gray-0;

  @include media-breakpoint-down(lg) {
    padding-left: 16px;
    padding-right: 16px;
  }

  &__heading {
    color: $gray-0;
    font-size: 48px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 24px;
    @include media-breakpoint-down(lg) {
      font-size: 36px;
      margin-bottom: 8px;
    }
  }
  &__text {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
}
