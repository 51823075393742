@import "../../styles/custom";

.hero {
  background-color: $business-800;
  padding-top: 32px;
  padding-bottom: 101px;
  background-repeat: no-repeat;
  background-position: bottom right;
  color: $white;
  z-index: 1;

  @include media-breakpoint-up(md) {
    background-image: url("../../../public/img/hero_logo.png");
    background-size: contain;
  }

  @include media-breakpoint-up(xl) {
    padding-top: 78px;
    padding-bottom: 176px;
  }

  &--simple {
    background-image: none;
    padding-top: 32px;
    padding-bottom: 24px;

    @include media-breakpoint-up(lg) {
      padding-top: 64px;
      padding-bottom: 80px;
    }
  }

  & &__simple-title {
    margin: 0 0 0.5rem 0;
  }

  & &__main-title {
    margin: 0 0 0.5rem 0;
    font-size: 2.25rem;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: -0.2px;

    @include media-breakpoint-up(xl) {
      font-size: 3rem;
      letter-spacing: -0.5px;
    }

    p {
      font-size: 2.25rem;
      font-weight: 700;
      line-height: 130%;
      letter-spacing: -0.2px;
      margin: 0;
      padding: 0;

      @include media-breakpoint-up(xl) {
        font-size: 3rem;
        letter-spacing: -0.5px;
      }

      span {
        font-weight: 700;
        color: $business-300;
      }
    }
  }

  & &__sub-title {
    margin-bottom: 24px;

    @include media-breakpoint-up(xl) {
      margin-bottom: 40px;
    }

    p {
      font-size: 20px;
      line-height: 140%;
      margin: 0;
      padding: 0;

      span {
        font-weight: 600;
      }
    }
  }
}
