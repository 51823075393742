@import "../../../../../styles/custom";

.objectives-container {
  max-height: 640px;
  max-width: 297px;
  button {
    align-items: center;
  }
  div[data-component="Collapsible"] {
    margin-left: 8px;
  }
  div[data-component="Box"] {
    padding-top: 0;
  }
  .text-wrapper p {
    margin-top: 0;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 150% !important;
  }

  @include media-breakpoint-down(lg) {
    padding: 24px 16px;
    width: 100%;
    max-width: 100%;
  }
}
.table-of-contents-articles {
  margin-top: 8px;
  max-height: 640px;
  max-width: 297px;
  width: 100%;
  overflow-y: auto;
  scrollbar-width: 3px;
  padding: 32px 24px;
  border-radius: 4px;
  border: 1px $gray-200 solid;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
  position: sticky;
  @include media-breakpoint-down(lg) {
    background-color: $gray-50;
  }
  div[data-component="Accordion"] {
    button {
      padding: 24px 0px;
    }
    p {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0px;
      text-align: left;
    }
    @include media-breakpoint-down(lg) {
      width: 100%;
      background-color: $gray-50;
    }
  }
  ol ul {
    list-style: circle !important;

    li::before {
      content: "";
      width: 0;
      height: 0;
    }
  }
  ul {
    list-style: disc !important;
    padding-inline-start: 18px;
  }
  ul li {
    margin-bottom: 8px !important;
  }
  ol {
    padding-left: 0;
    list-style: none;
    left: 0;
  }
  ol[start] {
    counter-reset: list-item attr(start) - 1;
  }
  ol li {
    left: 0;
    margin-bottom: 8px !important;
    position: relative;
    padding-left: 32px !important;
    ol li {
      list-style: lower-alpha;
      padding-left: 0px;
      ul li {
        list-style: circle;
      }
    }
    ol li::before {
      content: "";
      width: 0;
      height: 0;
    }
  }

  ol li::before {
    content: counter(list-item);
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    width: 20px;
    height: 20px;
    color: $gray-0;
    background-color: $business-600;
    border-radius: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
  }

  &__accordion-divider {
    height: 1px;
    background-color: $gray-200;
  }
  &__accordion-header {
    margin: 0;
    padding: 16px;
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
    color: $gray-900;
  }
  &__accordion-group {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  &__accordion-content {
    padding-bottom: 16px;
  }
  &__details {
    width: 100%;
    border: none;
    outline: none;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    cursor: pointer;

    &[open] .table-of-contents-articles__text {
      display: block;
    }
  }
  &__summary::-webkit-details-marker {
    display: none;
  }

  &__summary {
    list-style: none;
  }

  &.table-of-contents-articles--rtl {
    direction: rtl;
    p {
      text-align: right !important;
    }
    .table-of-contents-articles__icon {
      // transform: rotate(180deg);
      // Dont know why it rotates in rtl, changed due to https://employeesgate.atlassian.net/browse/QKCV-1849
      margin-right: 0px;
      margin-left: 8px;
      @include media-breakpoint-down(md) {
        margin-right: 0px;
        margin-left: 6px;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    padding: 8px 24px 16px 24px;
    margin-top: 0px;
    width: 100%;
    max-width: 100%;
  }

  &__header {
    align-self: stretch;
    margin-bottom: 24px;
    color: $gray-900;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    word-wrap: break-word;
    @include media-breakpoint-down(lg) {
      padding-inline-start: 16px;
      margin-bottom: 0;
      padding-top: 24px;
      font-size: 24px;
      font-weight: 600;
      line-height: 140%;
    }
  }
  &__container {
    margin-bottom: 16px;
    align-self: stretch;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    @include media-breakpoint-down(lg) {
      margin-bottom: 24px;
    }
  }

  &__group {
    color: $business-500;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2px;
    display: flex;
    @include media-breakpoint-down(md) {
      align-items: center;
      justify-content: center;
      margin-bottom: 12px;
      gap: 1px;
    }
  }
  &__icon {
    margin-inline-end: 8px;
    width: 20px;
    height: 20px;
    position: relative;
    @include media-breakpoint-down(md) {
      margin-inline-end: 8px;
      width: 24px;
      height: 24px;
    }
  }
  &__icon-visited{
    margin-inline-end: 8px;
    width: 20px;
    height: 20px;
    position: relative;
    color: $business-800;
    @include media-breakpoint-down(md) {
      margin-inline-end: 8px;
      width: 24px;
      height: 24px;
    }
  }

  &__link {
    align-self: stretch;
    text-decoration: underline;
    color: $business-500;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    word-wrap: break-word;
    padding: 0 !important;

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }

    &:hover {
      color: $business-800;
    }

    &:focus-visible {
      background-color: $warning-500;
      color: $gray-900;
      outline: none;
    }
  }
  div[data-component="Collapsible"] {
    div{
      padding-bottom: 0px !important;
    }
  }

  &__link-visited {
    align-self: stretch;
    text-decoration: underline;
    color: $business-800;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    word-wrap: break-word;

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }

    &:hover {
      color: $business-600;
    }

    &:focus-visible {
      background-color: $warning-500;
      color: $gray-900;
      outline: none;
    }
  }
}
