@import "../../styles/custom";

.article-card-wrapper {
  .article-card {
    box-shadow: $shadow-light;
    border: none;
    position: relative;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }

    .kc-link {
      &:focus {
        &:not(:focus-visible) {
          box-shadow: none;
        }

        &:focus-visible {
          @include focus();
        }
      }
    }

    .card-body {
      padding: 40px 32px 48px;
    }

    .card-title {
      font-size: 24px;
      font-weight: 600;
      line-height: 140%;
      margin-bottom: 8px;
    }

    .card-link {
      color: $business-500;
      font-weight: 500;
    }

    &__entire-card-link {
      position: absolute;
      top: 0;
      inset-inline-start: 0;
      height: 100%;
      width: 100%;
    }
  }
}
