@import "../../../../../styles/custom";

.nitaqat-calc-page {
  padding-top: 32px;
  background-color: $gray-50;

  @include media-breakpoint-up(lg) {
    padding-top: 64px;
  }

  &__note {
    width: 100%;
    padding: 48px;
    border-top: 1px solid $gray-300;
    .container{
      margin: 0;
    }
    @include media-breakpoint-down(lg) {
      padding: 32px 16px 40px 16px
    }
    ul{
      list-style: disc;
      padding-inline-start: 16px;
    }
    &__first-line {
      margin-bottom: 16px;
    }
    &__header{
      @include media-breakpoint-down(lg) {
        margin-bottom: 8px;
        font-size: 24px;
        display: block;
      }
    
    }

    span {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
    }

    .nitaqat-calc-page__note__bold {
      font-weight: 600;
    }

    .nitaqat-calc-page__note__normal {
      font-weight: 500;
    }
  }
}
