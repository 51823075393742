@import "../../styles/custom";

.action-tile-wrapper {
  height: 100%;

  .action-tile {
    height: 100%;
    border: 0;
    box-shadow: $shadow-light;
    border-radius: 0.25rem;

    .card-body {
      padding: 35px 24px 24px 24px;
      display: flex;
      flex-direction: column;
      @include media-breakpoint-down(md) {
        background-color: $gray-50;
      }
    }

    .action-tile__card-block {
      display: block;
    }

    .card-title {
      color: $gray-900;
      line-height: 140%;
      overflow: hidden;
      -webkit-box-orient: vertical;
      font-weight: 600;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      display: -webkit-box;
      @include media-breakpoint-down(md) {
        margin-bottom: 32px;
      }
      @include media-breakpoint-up(md) {
        height: 56px;
      }
    }

    .action-tile__title-tight {
      margin-bottom: 8px;
      height: inherit;
    }

    .card-text {
      color: $gray-800;
      font-size: $font-size-sm;
      height: 42px;
      overflow: hidden;
      position: relative;
      line-height: 150%;
    }

    .action-tile__text-tight {
      margin-bottom: 24px;
      height: inherit;
    }

    &--with-tag {
      .card-title {
        margin-bottom: 4px;
      }

      .card-text {
        height: 42px;
      }
    }

    &__tag {
      margin-bottom: 16px;

      &.card-text {
        height: auto;
        font-size: 12px;
      }

      span {
        margin-inline-start: 8px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        line-height: 150%;
        color: $gray-800;
      }

      &--chapter {
        .action-tile__tag-icon {
          color: $gray-800;
        }
      }

      &--article {
        .action-tile__tag-icon {
          color: $gray-800;
        }
      }
    }

    &__tag-icon {
      position: relative;
      top: -1px;
    }

    &--business {
      border-top: 3px solid $business-500;
      transition: all 0.1s ease-out;

      &:hover,
      &:active {
        background-color: $business-100;
        border-top: 3px solid $business-600;
        transition: all 0.1s ease-out;
      }

      &:focus {
        &:not(:focus-visible) {
          box-shadow: none;
        }

        &:focus-visible {
          @include focus();
        }
      }

      .action-tile__tag--service {
        .action-tile__tag-icon {
          color: $business-500;
        }
      }
    }

    &--individuals {
      border-top: 3px solid $individuals-500;
      transition: all 0.1s ease-out;
      background-color: $white;

      &:hover,
      &:active {
        background-color: $individuals-100;
        border-top: 3px solid $individuals-600;
        transition: all 0.1s ease-out;
      }

      &:focus {
        &:not(:focus-visible) {
          box-shadow: none;
        }

        &:focus-visible {
          @include focus();
        }
      }

      .action-tile__tag--service {
        .action-tile__tag-icon {
          color: $individuals-500;
        }
      }
    }

    &--neutral {
      border-top: 3px solid $gray-800;
      transition: all 0.1s ease-out;
      background-color: $white;

      &:hover,
      &:active {
        background-color: $gray-100;
        border-top: 3px solid $gray-900;
        transition: all 0.1s ease-out;
      }

      &:focus {
        &:not(:focus-visible) {
          box-shadow: none;
        }

        &:focus-visible {
          @include focus();
        }
      }
    }

    &--providers {
      border-top: 3px solid $individuals-400;
      transition: all 0.1s ease-out;
      background-color: $white;

      &:hover,
      &:active {
        background-color: $individuals-100;
        border-top: 3px solid $individuals-600;
        transition: all 0.1s ease-out;
      }

      &:focus {
        &:not(:focus-visible) {
          box-shadow: none;
        }

        &:focus-visible {
          @include focus();
        }
      }

      .action-tile__tag--service {
        .action-tile__tag-icon {
          color: $individuals-400;
        }
      }
    }

    &--business,
    &--individuals,
    &--providers,
    .labor-education-materials  &--neutral {
      @include media-breakpoint-down(md) {
        background-color: $gray-50;
        border: 0;
        box-shadow: none;
        border-bottom: 1px solid $gray-200;

        &:hover,
        &:active {
          background-color: $gray-50;
          border: 0;
          box-shadow: none;
          border-bottom: 1px solid $gray-200;
        }

        &:focus {
          &:focus-visible {
            box-shadow: $focus-outline;
          }
        }

        .card-body {
          padding: 24px 0;
        }

        .card-title {
          font-size: 16px;
          color: $business-500;
          margin-bottom: 4px;
          line-height: 150%;
          font-weight: 600;
          height: auto;
        }

        .card-text {
          height: auto;
        }
      }
    }

    &__tile-description {
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;

      span {
        position: absolute;
        bottom: 0;
        inset-inline-end: 0;
        background-color: white;
        padding-left: 8px;
      }
    }
  }
}
