@import "../../styles/custom";

.action-tiles-group {
  background-color: $gray-50;

  @include media-breakpoint-up(lg) {
    padding: 36px 0;
  }

  .container {
    position: relative;
  }
  .accordion-body .container {
    margin-inline-start: 0;
  }
  .accordion-body .container{
    margin-inline-start: 0;
  }

  .row {
    align-items: stretch;
    @include media-breakpoint-down(md) {
      gap: 24px;
    }
   
  }

  & &__group-cta {
    display: block;
    margin-top: 24px;
    @include media-breakpoint-down(md) {
      padding-bottom: 64px;
    }

    .kc-link__label {
      &::after {
        height: 1px;
      }
    }

    @include media-breakpoint-up(md) {
      display: inline;
      margin-top: 0;
      position: absolute;
      top: 0;
      inset-inline-end: 0;
      margin-inline-end: 19px;
      transform: translateY(50%);
    }

    .arrow-right-icon {
      position: absolute;
      color: $business-500;
    }

    a {
      line-height: 150%;
    }
  }

  & &__link {
    text-decoration: none;

    .action-tile {
      transition: none;
    }

    &:focus {
      &:focus-visible {
        .action-tile {
          border-top: none;
          padding-top: 3px;
          display: block;
          @include focus();
        }
      }
    }
  }

  & &__title-link {
    text-decoration: none;
    width: 100%;

    &:focus {
      &:focus-visible {
        width: 100%;
        @include focus();
      }
    }
  }

  & &__title {
    display: flex;
    justify-content: space-between;
    &__sentence{

      color: $gray-900;
      font-weight: 600;
    }

    @include media-breakpoint-up(md) {
      &__sentence{
        font-size: 32px;
        max-width: 70%;
      }
     
      align-items: center;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
    }
  }

  & &__title-icon-wrapper {
    width: 32px;
    height: 32px;
    border: 2px solid $gray-300;
    border-radius: 50%;
    margin-inline-start: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    .arrow-right-icon {
      color: $business-500;
    }
  }

  & &__description {
    line-height: 150%;
    font-weight: 400;
    color: $gray-800;

    @include media-breakpoint-up(md) {
      margin-top: 20px;
      margin-bottom: 0;
    }
  }

  & &__card-column {
    &.col {
      margin-top: 16px;

      @include media-breakpoint-up(md) {
        margin-top: 32px;
      }

      &:last-of-type {
        .action-tile {
          @include media-breakpoint-down(md) {
            border-bottom: none;
          }
        }
      }
    }
  }

  .custom-card {
    height: 100%;
  }

  &--neutral {
    position: relative;

    @include media-breakpoint-up(md) {
      padding: 32px 0;
    }

    .action-tiles-group__title {
      font-size: 20px;
      line-height: 140%;
      font-weight: 600;

      @include media-breakpoint-up(md) {
        font-size: 32px;
      }
    }
    .action-tiles-group__description {
      margin-top: 8px;
      margin-bottom: 32px;

      @include media-breakpoint-up(md) {
        margin-top: 20px;
        margin-bottom: 0;
      }
    }
  }

  &--business,
  &--individuals,
  &--providers,
  .labor-education-materials &--neutral{
    .arrow-right-icon {
      color: $business-500;
    }

    @include media-breakpoint-only(md) {
      margin-bottom: 64px;
    }

    .action-tiles-group__card-column.col {
      margin-top: 0;

      @include media-breakpoint-up(md) {
        margin-top: 32px;
      }
    }

    .action-tiles-group__title {
      display: none;

      @include media-breakpoint-up(md) {
        display: flex;
        align-items: center;
      }
    }

    .action-tiles-group__description {
      margin-bottom: 48px;

      @include media-breakpoint-up(md) {
        display: block;
        margin-bottom: 0px;
      }
    }
  }

  &--neutral {
    .action-tiles-group__title {
      a {
        display: none;
      }
    }
  }

  &--without-accordion {
    &.action-tiles-group--business,
    &.action-tiles-group--individuals,
    &.action-tiles-group--providers,
     .labor-education-materials &.action-tiles-group--neutral{
      padding-top: 32px;

      .action-tiles-group__title {
        font-size: 32px;
        font-weight: 600;
        line-height: 130%;
        display: flex;

        @include media-breakpoint-down(md) {
          justify-content: space-between;
          margin-bottom: 8px;
          font-size: 20px;
          font-weight: 600;
          line-height: 140%;
        }
      }

      .action-tiles-group__card-column.col {
        &:last-child {
          @include media-breakpoint-down(md) {
            .action-tile {
              border-bottom: none;
            }
          }
        }
      }
    }
  }

  .accordion-header-custom {
    background-color: $gray-50;
    padding: 0 16px;
    margin-bottom: 0;
    &.button-without-underline {
      button {
        border-bottom: none !important;
      }
    }
    button {
      background-color: $gray-50;
      font-size: 20px;
      font-weight: 600;
      line-height: 140%;
      padding: 32px 0 24px 0;
      border-bottom: 1px solid var(--grayscale-grayscale-200, #e6e6e7);
      box-shadow: none;
    }
  }

  & &__accordion {
    display: flex;
    flex-direction: column;
    .accordion-item {
      background-color: $gray-50;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    .accordion-button {
      padding: 32px 16px 24px;
      position: relative;
      background-color: $gray-50;
      font-size: 20px;
      font-weight: 600;
      line-height: 140%;
      color: $gray-900;

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        inset-inline-start: 16px;
        height: 1px;
        width: calc(100% - 32px);
        background-color: $gray-200;
        visibility: visible;
      }

      &::after {
        width: 1rem;
        height: 1rem;
        background-size: 1rem;
        background-image: var(--bs-accordion-btn-icon);
      }
      @at-root {
        body[lang="ar"] {
          .action-tiles-group {
            .accordion-button {
              &::after {
                margin-right: auto;
                margin-left: 0;
              }
            }
          }
        }
      }

      &:not(.collapsed) {
        box-shadow: none;

        &::before {
          visibility: hidden;
        }
      }
    }

    .accordion-body {
      background-color: $gray-50;
      padding: 0;
    }
  }

  & &__all-articles-link {
    display: block;
    text-decoration: none;
    color: $business-500;
    padding: 0 0px 24px;
    position: relative;
    font-weight: 600;

    &:hover {
      color: $business-500;

      @include media-breakpoint-up(md) {
        color: $gray-600;
        text-decoration-thickness: 2px;
      }
    }

    &:focus {
      @include media-breakpoint-up(md) {
        text-decoration-thickness: 2px;
      }
    }

    @include media-breakpoint-up(md) {
      font-size: 16px;
      font-weight: 500;
      color: $gray-900;
      padding: 0;
      text-decoration: underline;
      text-underline-offset: 3px;
      text-decoration-thickness: 1px;
      position: relative;
    }
  }
}
