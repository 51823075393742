@import "../../styles/custom";

.benefits-banner-background {
  background-color: $gray-50;
}

.benefits-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 72px;

  @include media-breakpoint-up(lg) {
    padding-top: 120px;
  }

  &__main-wrapper {
    max-width: 100%;
    @include media-breakpoint-up(lg) {
      max-width: 1128px;
      padding: 0 16px;
    }
  }

  &__title-box {
    margin-bottom: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-up(lg) {
      margin-bottom: 120px;
    }
  }

  &__main-title {
    font-size: 32px;
    line-height: 41px;
    font-weight: 600;
    color: $gray-900;
    margin-bottom: 16px;

    @include media-breakpoint-up(lg) {
      font-size: 36px;
      line-height: 46px;
    }
  }

  &__blue-title {
    color: $business-500;
    font-weight: 600;
  }
  &__green-title {
    color: $individuals-500;
    font-weight: 600;
  }
  &__secondary-title {
    font-size: 16px;
    line-height: 24px;
    color: $gray-900;
    margin-bottom: 0;
  }

  &__article-box {
    max-width: 456px;
  }

  &__article-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 72px;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
      justify-content: center;
      margin-bottom: 120px;
    }
  }

  &__article-wrapper-left {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 72px;
    align-items: center;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
      margin-bottom: 120px;
      justify-content: center;
    }
  }

  &__article-img {
    position: relative;
    width: 100%;
    max-width: 456px;
    max-height: 551px;
    border-radius: 8px;
    @include media-breakpoint-up(lg) {
      width: 456px;
      height: 551px;
    }
  }

  &__article-img-right {
    position: relative;
    border-radius: 8px;
    width: 100%;
    max-width: 456px;
    max-height: 551px;
    @include media-breakpoint-up(lg) {
      @at-root {
        body[lang="ar"] {
          .benefits-banner__article-img-right {
            @include media-breakpoint-up(lg) {
              margin-left: 0px;
            }
          }
        }
      }
      margin-left: 120px;
      width: 456px;
      height: 551px;
    }
  }

  &__banner {
    position: absolute;
    top: 66%;
    background: $white;
    width: 145px;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    box-shadow: 46px 110px 48px rgba(0, 0, 0, 0.01),
      26px 62px 40px rgba(0, 0, 0, 0.05), 12px 27px 30px rgba(0, 0, 0, 0.09),
      3px 7px 16px rgba(0, 0, 0, 0.1), 0 0 0 rgba(0, 0, 0, 0.1);

    @include media-breakpoint-up(lg) {
      width: 185px;
      height: 117px;
    }

    &__icon-box {
      background: $business-500;
      border-radius: 50%;
      width: 48px;
      height: 48px;
      position: absolute;
      top: -28px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include media-breakpoint-up(lg) {
        width: 56px;
        height: 56px;
      }
    }
    &__icon-box-green {
      background: $individuals-500;
      border-radius: 50%;
      width: 56px;
      height: 56px;
      position: absolute;
      top: -28px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__divider-main {
      height: 10px;
      width: 128px;
      background: #9fb2c4;
      border-radius: 9px;
      margin-bottom: 19px;
      opacity: 0.3;
      margin-top: 10px;
    }
    &__divider-secondary {
      height: 7px;
      width: 82px;
      background: #9fb2c4;
      border-radius: 9px;
      opacity: 0.3;
    }
  }

  &__banner__right {
    right: -8px;
    @include media-breakpoint-up(lg) {
      right: -154px;
    }
  }
  @at-root {
    body[lang="ar"] {
      .benefits-banner__banner__right {
        right: -8px;
        @include media-breakpoint-up(lg) {
          right: -34px;
        }
      }
    }
  }

  &__banner__left {
    right: -8px;
    @include media-breakpoint-up(lg) {
      right: -34px;
    }
  }

  &__image-box {
    position: relative;
    max-width: 456px;
    margin-bottom: 48px;
    margin-right: 0;
    @include media-breakpoint-up(lg) {
      margin-right: 120px;
      margin-bottom: 0;
    }
  }

  &__image-box-right {
    position: relative;
    max-width: 456px;
    margin-bottom: 48px;
    margin-right: 0;
    @include media-breakpoint-up(lg) {
      margin-right: 120px;
      margin-bottom: 0;
    }
  }

  @at-root {
    body[lang="ar"] {
      .benefits-banner__image-box-right {
        margin-left: 0;
        margin-right: 0;
        @include media-breakpoint-up(lg) {
          margin-left: 0;
          margin-right: 120px;
        }
      }
    }
  }

  @at-root {
    body[lang="ar"] {
      .benefits-banner__image-box {
        margin-left: 0;
        @include media-breakpoint-up(md) {
          margin-left: 120px;
          margin-right: 0;
        }
      }
    }
  }

  &__article-title {
    color: $gray-900;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 32px;
    @include media-breakpoint-up(lg) {
      font-size: 36px;
      margin-bottom: 40px;
    }
  }

  &__article-list {
    padding-left: 1em;
    padding-right: 0;
    margin-bottom: 32px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 40px;
    }
    li {
      margin-bottom: 32px;
    }
    li span {
      font-size: 16px;
      font-weight: 600;
      color: $gray-900;
    }
    li p {
      font-size: 16px;
      font-weight: 400;
      color: $gray-900;
    }
    ::marker {
      color: $business-500;
      font-size: 1.5em;
    }
  }
  @at-root {
    body[lang="ar"] {
      .benefits-banner__article-list {
        padding-left: 0;
        padding-right: 1em;
      }
    }
  }

  & &__secondary-article-button {
    display: flex;
    justify-content: center;
    width: 100%;
    background: $individuals-500;
    color: white;
    border: 0;
    border-radius: 4px;
    padding: 12px 24px;
    text-decoration: none;

    @include media-breakpoint-up(md) {
      width: fit-content;
    }

    &:hover {
      background-color: $individuals-600;
      color: $white;
    }

    &:active {
      background-color: $individuals-900;
      color: $white;
    }

    &:focus {
      &:not(:focus-visible) {
        box-shadow: none;
      }

      &:focus-visible {
        @include focus();
      }
    }
  }
  & &__article-button {
    display: flex;
    justify-content: center;
    width: 100%;
    background: $business-500;
    color: white;
    border: 0;
    border-radius: 4px;
    padding: 12px 24px;
    text-decoration: none;

    @include media-breakpoint-up(md) {
      width: fit-content;
    }

    &:hover {
      background-color: $business-600;
      color: $white;
    }

    &:active {
      background-color: $business-700;
      color: $white;
    }

    &:focus {
      &:not(:focus-visible) {
        box-shadow: none;
      }

      &:focus-visible {
        @include focus();
      }
    }
  }
  &__main-features {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__secondary-title {
      color: $gray-600;
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 12px;
      text-transform: uppercase;
    }
    &__title-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;
      text-align: center;
      @include media-breakpoint-up(lg) {
        margin-bottom: 50px;
      }
    }
    &__tile-box {
      @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 96px;
      }
    }
    &__tile {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 264px;
      padding: 24px 0;
      margin: 0 12px;
    }
    &__tile-title {
      color: $gray-900;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 4px;
      @include media-breakpoint-up(md) {
        margin-bottom: 16px;
      }
    }
    &__tile-text {
      color: $gray-700;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      margin-bottom: 0;
      @include media-breakpoint-up(md) {
        margin-bottom: 16px;
      }
    }
    &__icon {
      margin-bottom: 35px;
      color: $business-500;
    }
    &__icon__green {
      margin-bottom: 35px;
      color: $individuals-500;
    }
  }
  &__second_article_wrapper {
    margin-top: 48px;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }
}
