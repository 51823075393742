@import "../../../../../styles/custom";

.end-of-service-reward-page {
  padding-top: 32px;
  background-color: $gray-50;

  @include media-breakpoint-up(lg) {
    padding-top: 64px;
  }
}
