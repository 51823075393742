@import "../../../styles/custom";

.labor-education-materials {
  padding: 20px;
  margin-top: 36px;

  .action-tiles-group {
    &__card-column{
      height: 160px;
      @include media-breakpoint-down(lg) {
        height: auto;
      }
    }
    &__title {
      font-size: 24px;
      font-weight: 600;
      line-height: 130%;
    }
    button{
      @include media-breakpoint-down(lg) {
        padding: 0;
      }
      
    }
    &__accordion{
      @include media-breakpoint-down(lg) {
        padding-top: 24px;
        padding-bottom: 24px;
      }
    }
    .container{
      max-width: 100% !important;
    }
    @include media-breakpoint-down(lg) {
      width: 100%;
      .accordion-body{
        padding: 0;
      }
      .accordion-header-custom {
        padding: 0;
      }
    }
    
  }
  .hero-search-input{
    margin-bottom: 32px;
  }

  @include media-breakpoint-down(lg) {
    padding-left: 16px;
    padding-right: 16px;
  }

  &__show-more-less-container {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: flex-start; /* Align to the start of the container */
    margin-top: 1rem; /* Add some space from the content above */
  }

  &__filters-section {
    @include media-breakpoint-down(lg) {
      margin-inline-end: 0;
    }
  }

  &__material-header {
    font-size: 32px;
    font-weight: 600;
    line-height: 130%;
    margin-bottom: 32px;
    @include media-breakpoint-down(lg) {
      font-size: 24px;
    }
  }
  &__show-more {
    color: #0060ff;
    font-size: 14px;
    cursor: pointer;
    text-decoration: underline !important;
  }

  &__show-less {
    color: #0060ff;
    font-size: 14px;
    cursor: pointer;
    text-decoration: underline;
  }

  &__materials-section {
    margin-bottom: 48px;
    @include media-breakpoint-down(lg) {
      margin-bottom: 0px;
    }

    h3 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 10px;
    }
        
  }

  &__filter {
    padding: 20px;
    border: 1px solid #e5e5e5;
    border-radius: 8px;

    &__title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    &__section {
      margin-bottom: 20px;
    }

    &__label {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    
  }
}
