@import "../../styles/custom";

.quote-container {
  display: flex;
  justify-content: space-between;
  margin-left: 64px;
  margin-right: 64px;
  max-width: calc(100vw - 128px);
  background-color: $business-800;
  border-radius: 16px;

  @include media-breakpoint-up(xl) {
    padding: 92px 72px;
  }

  @include media-breakpoint-down(xl) {
    flex-direction: column;
    flex-wrap: wrap;
    padding: 40px 24px 48px 24px;
    margin-left: 16px;
    margin-right: 16px;
    max-width: calc(100vw - 32px);
  }

  &__user-img {
    width: 64px;
    height: 64px;
  }

  &__content {
    @include media-breakpoint-down(xl) {
      text-align: center;
      max-height: 100%;
    }

    .quote-container__icon {
      width: 55px;
      height: 38px;
      color: $business-300;
      font-size: 20px;
    }

    .quote-container__text {
      @include media-breakpoint-down(xl) {
        margin: 32px auto;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 140%;
        text-align: center;
      }

      @include media-breakpoint-up(xl) {
        width: 830px;
      }

      margin: 40px 0 32px 0;
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 130%;
      letter-spacing: -0.2px;
      color: $white;
    }

    .quote-container__user-details {
      gap: 24px;
      @include media-breakpoint-down(xl) {
        flex-direction: column;
        gap: 16px;
      }

      display: flex;
      align-items: center;

      &--content {
        display: flex;
        flex-direction: column;

        .quote-container__user-details--username {
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 150%;
          color: $white;
        }

        .quote-container__user-details--job {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          text-align: center;
          color: $gray-300;
        }
      }
    }
  }

  &__actions {
    @include media-breakpoint-down(xl) {
      margin: 40px auto 0;
    }

    display: flex;
    flex-direction: column;
    .quote-container__buttons {
      @include media-breakpoint-down(xl) {
        display: none;
      }

      display: flex;
      flex: 1;
      &--button:first-child {
        margin-right: 8px;
      }
      &--button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: $business-700;
        border: none;
        color: $business-600;
      }

      &__active-button {
        background-color: $business-600;
        color: $white;
      }
    }

    @at-root body.arabic {
      .quote-container__buttons {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        flex-direction: row-reverse;
      }
    }

    .quote-container__dots {
      display: flex;
      margin-left: auto;
      &--dot {
        width: 8px;
        height: 8px;
        background-color: $business-700;
        border-radius: 50%;
        margin-right: 4px;
        cursor: pointer;
      }

      &__active-dot {
        background-color: $white;
      }
    }
  }
}
