@import "../../styles/custom";

.custom-loader {
  color: $business-500;
  opacity: 0.6;
}

.custom-page-loader {
  &__wrapper {
    position: absolute;
    top: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #0a0e14, $alpha: 0.5);
    display: flex;
    justify-content: center;
    padding-top: 80px;

    @include media-breakpoint-down(md) {
      padding-top: 0;
      align-items: center;
    }
  }

  &__spinner-block {
    padding: 16px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    background-color: $business-600;
    border-radius: 4px;
    color: white;
    max-width: fit-content;
    max-height: 56px;
  }

  &__spinner {
    color: $gray-500;
  }
}
