@import "../../../styles/custom";

.info-box {
  display: flex;
  width: 474px;
  height: 80px;
  background-color: $info-50;
  border: 1px solid $info-100;
  border-left: 2px solid $info-500;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 32px;

  @include media-breakpoint-down(lg) {
    width: 100%;
    height: unset;
  }

  &__icon {
    margin-inline-end: 12px;
  }

  &__text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  @at-root body .info-box ul {
    padding-inline-start: 0px;
  }
}
