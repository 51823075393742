@import "../../../../styles/custom";
.service-overview-hero-section {
  color: $gray-50;
  background: $business-800;
  padding-top: 40px;
  padding-bottom: 80px;

  &__headline {
    align-self: stretch;
    color: $gray-100;
    font-size: 48px;
    font-weight: 700;
    word-wrap: break-word;
    margin-bottom: 32px;
  }
  &__text {
    text-align: center;
    align-self: stretch;
    color: $gray-100;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    word-wrap: break-word;
  }

  &__group {
    gap: 10px;
    display: flex;
  }

  &__button-link {
    padding: 11px 24px 13px 24px;
    background: none;
    align-self: stretch;
    border: none;
    background: $business-500;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    color: white;

    &:hover,
    &:active {
      color: white;
      background-color: $business-700;
    }

    &:focus-visible {
      outline: solid 1px #000000;
      background-color: $warning-500;
      color: $gray-900;
    }
  }

  @include media-breakpoint-down(lg) {
    padding-top: 32px;
    padding-bottom: 56px;
    padding-left: 16px;
    padding-right: 16px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    &__group {
      background: $business-500;
      border-radius: 4px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      display: flex;
    }
    &__button {
      background: none;
      border: none;
      display: inline-flex;
    }
    &__text {
      text-align: center;
      color: $gray-100;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
  }
}
.service-overview-page .feedback .container {
  max-width: 100vw;
  padding: 40px 156px !important;
}
.service-overview-page .container {
  @include media-breakpoint-down(lg) {
    max-width: 100vw !important;
  }
}
