@import "../../../../../../styles/custom";

.journey-article-hero {
  padding-top: 64px;
  padding-bottom: 80px;
  background-color: $business-800;
  color: $gray-50;
  position: relative;
  font-size: 20px;
  // background: $business-800 url("./journeyArticleDesktop.png") no-repeat right
  //   37px bottom 0;
  // background-size: 676px 393px;
  @include media-breakpoint-down(lg) {
    padding-bottom: 40px;
    padding-top: 32px;
  }
  &__row{
    @include media-breakpoint-down(lg) {
      margin-left: 16px;
      margin-right: 16px;
    }
    
  }
  &__left-col{
    margin-inline-end: auto;
    margin-inline-start: 0;
  }
  &__right-col{
    margin-inline-end: 0;
    margin-inline-start: auto;
  }

  &__text{
    font-size: 20px;
    @include media-breakpoint-down(lg) {
      font-size: 16px;
    }
  }

  &__headline {
    align-self: stretch;
    color: $gray-100;
    font-size: 48px;
    font-weight: 700;
    word-wrap: break-word;
    @include media-breakpoint-down(lg) {
      font-size: 36px;
    }
  }
  img.journey-article-hero-video-attribute {
    width: 100%;
    height: auto;
  }
}
.journey-article-hero--rtl {
  direction: rtl;
  // background: $business-800 url("./journeyArticleDesktop.png") no-repeat left
  //   37px bottom 0px;
  // background-size: 676px 393px;
}
