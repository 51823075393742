@import "../../styles/custom";

.breadcrumbs-kc {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  font-size: 14px;
  line-height: 150%;
  font-weight: 500;
  margin-bottom: 8px;

  &--dark {
    .breadcrumbs-kc__back-button{
      color: $business-500;
    }
  }

  li {
    margin-top: 0;
    margin-bottom: 0;
  }
  &__link{
    &--light{
      color: $gray-300;
    }
    &--dark{
      color: $gray-600;
    }
  }

  .icon {
    margin: 0 4px;
  }
  &__back-button {
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
  }
  &--light {
    .breadcrumbs-kc__link--back-link {
      a {
        color: white;
      }
    }
  }

  &__link {
    a {
      text-decoration: none;
      position: relative;
      padding-bottom: 1px;
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-underline-offset: 4px;

      &:hover {
        color: $gray-200;
        text-decoration-thickness: 2px;
      }

      &:active {
        color: $gray-200;
        text-decoration-thickness: 1px;
      }

      &:focus {
        &:not(:focus-visible) {
          box-shadow: none;
        }

        &:focus-visible {
          @include focus();
        }
      }
    }

    &--back-link {
      font-size: 16px;

      a {
        display: flex;
        align-items: center;
      }

      .icon {
        margin-inline-start: 0;
      }
    }

    &--dark {
      a {
        color: $business-500;

        &:hover {
          color: $business-600;
        }

        &:active {
          color: $business-700;
        }
      }
    }

    &--light {
      a {
        color: $white;
      }
    }

    a:after {
      background-color: $white;
    }
  }

  &__active {
    &--dark {
      color: $gray-600;
    }

    &--light {
      color: $gray-300;
    }
  }
}