@import "../../styles/custom";

.nav-cards-group {
  padding: 64px 0;
  background-color: $gray-100;
  background-position: bottom right;
  background-repeat: no-repeat;

  @include media-breakpoint-up(lg) {
    padding: 104px 0 132px 0;
    background-image: url("../../../public/img/big-grey-logo.png");
  }

  a {
    text-decoration: none;
  }

  .row {
    align-items: stretch;
  }

  & &__cards-row {
    .col {
      margin-bottom: 24px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }

      .kc-link {
        &:focus {
          outline: none;

          &:not(:focus-visible) {
            .nav-card {
              box-shadow: none;
            }
          }

          &:focus-visible {
            .nav-card {
              border-top: none;
              padding-top: 43px;
              @include focus();
            }
          }
        }
      }
    }
  }

  & &__title {
    font-size: 32px;
    font-weight: 600;
    line-height: 130%;
    margin-bottom: 8px;

    @include media-breakpoint-up(lg) {
      font-size: 48px;
      margin-bottom: 20px;
    }
  }

  & &__description {
    margin-bottom: 48px;
  }
}
