@import "../../../../../styles/custom";

.icon-tooltip-container {
  @include media-breakpoint-down(sm) {
    &__container {
      margin-inline-start: auto;
    }
  }
}

.icon-tooltip-content {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 12px;
  border: 1px solid black;
  border-radius: 4px;
  background-color: white;
  color: black;
  white-space: nowrap;
  z-index: 1000;
}

.text-wrapper--rtl {
  direction: rtl;

  ol li::before {
    left: auto;
    right: 0;
  }
  ol ul {
    li {
      padding-right: 0px;
    }
  }
  ul li::marker {
    margin-left: 12px;
    color: $business-600;
  }
  ol {
    padding-right: 0;
    right: 0;
    left: auto;
  }
  ol li {
    right: 0;
    padding-right: 32px;
    ol li {
      padding-right: 0px;
    }
  }
}

.text-wrapper {
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 8px;
    margin-block-end: 8px;
    padding-inline-start: 16px;
  }
  ol ul {
    list-style: circle;
    li {
      padding-left: 0px;
    }
    li::before {
      content: "";
      width: 0;
      height: 0;
    }
  }
  ul li {
    margin-bottom: 8px !important ;
  }
  ul li::marker {
    margin-right: 12px !important ;
    color: $business-600 !important ;
  }
  ol {
    margin-block-start: 8px;
    padding-left: 0;
    list-style: none;
    left: 0;
  }
  ol[start] {
    counter-reset: list-item attr(start) - 1;
  }
  ol li {
    left: 0;
    margin-bottom: 8px;
    position: relative;
    padding-left: 32px;
    ol li {
      list-style: lower-alpha;
      padding-left: 0px;
      ul li {
        list-style: circle;
      }
    }
    ol li::before {
      content: "";
      width: 0;
      height: 0;
    }
  }

  ol li::before {
    content: counter(list-item);
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    width: 20px;
    height: 20px;
    color: $gray-0;
    background-color: $business-600;
    border-radius: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
  }

  .text-copy-innerHtml-icon {
    margin-left: 4px;
    margin-top: 4px;
    visibility: hidden;
    cursor: pointer;
    @include media-breakpoint-down(md) {
      visibility: visible;
    }
  }

  h1:hover .text-copy-innerHtml-icon,
  h2:hover .text-copy-innerHtml-icon,
  h3:hover .text-copy-innerHtml-icon {
    visibility: visible;
  }

  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    a {
      color: $business-500;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      text-decoration-line: underline;
    }
    strong {
      font-weight: 600;
    }
  }
  h2 + p {
    margin-top: 16px;
  }
  h2 + h4 {
    margin-top: 20px;
    @include media-breakpoint-down(md) {
      margin-top: 16px;
    }
  }
  h4 + p {
    margin-top: 4px;
  }

  h2 {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
  }
  h4 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }
  h5 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  .title-h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
  }
  .title-h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 150%;
  }

  .title-h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
  }

  .title-h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
  }

  .body-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
  }
}

.tooltip-container {
  position: relative;
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: normal;
  color: $business-500;
  text-decoration-line: underline;
}

.toast.toast-copy-message {
  z-index: 100;
  box-shadow: none;
  width: calc(100% - 32px);
  position: fixed;
  bottom: 32px;

  @include media-breakpoint-up(lg) {
    width: calc(map-get($container-max-widths, "lg") * 0.66);
  }

  @include media-breakpoint-up(xl) {
    width: calc(map-get($container-max-widths, "xl") * 0.66);
  }

  @include media-breakpoint-up(xxl) {
    width: calc(map-get($container-max-widths, "xxl") * 0.66);
  }

  @include media-breakpoint-up(xxxl) {
    width: calc(map-get($container-max-widths, "xxxl") * 0.66);
  }

  .toast-body {
    display: flex;
    align-items: center;
    color: $gray-900;
    background-color: $success-50;
    padding: 16px;
    font-size: 16px;
    line-height: 150%;

    .checkmark-success {
      background-color: $success-500;
      color: $white;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-inline-end: 12px;

      .icon {
        height: 12px;
        width: 12px;
        stroke-width: 2px;
        position: relative;
        inset-block-end: 4px;
        inset-inline-start: 3px;
        stroke: $white;
      }
    }

    .toast-close-button {
      background: transparent;
      border: none;
      padding: 0;
    }

    p {
      flex-grow: 1;
      margin: 0;
    }
  }
}
