@import "../../../../styles/custom";
.user-details-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px 32px 24px 32px;

  &__col {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  &__icon-placeholder {
    width: 24px;
    height: 24px;
    background-color: #ced4da;
    border-radius: 50%;
    // Placeholder styles, you can customize based on your icons
  }

  &__title {
    font-size: 12px;
    font-weight: 700;
    line-height: 150%; 
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: $gray-600
  }

  &__content {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    color: $business-600;
  }

  &__icon{
    color: $business-600;
  }
}
