@import "../../../styles/custom";

.calling-service-page {
  &__hero {
    background-color: $business-800;
    padding: 32px 0 24px;

    @include media-breakpoint-up(md) {
      padding: 64px 0 80px;
      width: auto;
    }

    h2 {
      margin: 0;
      color: white;
      font-size: 36px;
      font-weight: 700;

      @include media-breakpoint-up(md) {
        font-size: 48px;
        width: auto;
      }
    }
  }

  &__content {
    padding: 40px 0 64px;

    @include media-breakpoint-up(md) {
      padding: 56px 0 120px;
      width: auto;
    }

    h3 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 16px;

      @include media-breakpoint-up(md) {
        font-size: 32px;
      }
    }

    h5 {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 16px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 24px;
    }

    fieldset {
      margin-bottom: 32px;
      position: relative;
    }

    input {
      appearance: none;
      @include media-breakpoint-down(lg) {
        display: inline;
      }

      &:checked {
        &::after {
          background-image: url("data:image/svg+xml, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22none%22%20viewBox%3D%220%200%2024%2024%22%3E%0A%20%20%3Crect%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22%23134359%22%20rx%3D%224%22%2F%3E%0A%20%20%3Cpath%20fill%3D%22%23fff%22%20fill-rule%3D%22evenodd%22%20d%3D%22M17.12%208.17814c.2698.25054.2854.67236.0349.94217L10.9643%2015.787a.66651.66651%200%200%201-.4859.213.66684.66684%200%200%201-.48764-.2093l-3.14312-3.3333c-.25259-.2679-.2402-.6898.02768-.9424.26788-.2526.68981-.2402.9424.0276l2.65438%202.815%205.7057-6.14457c.2506-.2698.6724-.28542.9422-.03489Z%22%20clip-rule%3D%22evenodd%22%2F%3E%0A%3C%2Fsvg%3E");
          transition: all 0.15s ease-in-out;
          border: none;
        }
      }

      &::after {
        position: absolute;
        top: 12px;
        transform: translateY(-50%);
        inset-inline-start: 0;
        content: "";
        display: block;
        height: 24px;
        width: 24px;
        background-color: white;
        border: 1px solid $gray-600;
        border-radius: 4px;
        z-index: 100;
        transition: all 0.15s ease-in-out;
      }

      & + label {
        margin-inline-start: 35px;
      }
    }
  }

  &__start-call-btn {
    padding: 12px 24px;
    border-radius: 4px;
    background-color: $business-500;
    border: none;
    color: white;
    width: 100%;
    text-align: center;

    @include media-breakpoint-up(md) {
      width: auto;
    }

    &:hover {
      background-color: $business-700;
    }

    &:disabled {
      background-color: $gray-200;
      color: $gray-500;
    }
  }
}
.calling-service-page .feedback .container{
  max-width: 100vw;
  padding: 40px 156px !important;
}