@import "../../../../../../styles/custom";
.interludesvideo {
  background-color: $business-800;
  padding: 64px 156px 64px 156px !important;
  color: $gray-0;

  @include media-breakpoint-down(md) {
    padding: 16px 32px 16px 32px !important;
  }

  &__videoContent {
    margin-bottom: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
  &__header {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }
}
