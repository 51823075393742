@import "../../../../../styles/custom";

.icon-tooltip-container {
  position: relative;
  display: inline-block;
  visibility: hidden;

  &:hover {
    cursor: pointer;

    .icon-tooltip-container__container {
      visibility: visible;
    }
  }

  &__container {
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    top: -60px;
    inset-inline-start: calc(50% - 50px);
    position: absolute;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10), 0px 8px 24px 0px rgba(0, 0, 0, 0.12);
    padding: 16px 20px 20px 20px;
    border: 1px solid $gray-100;
    border-radius: 8px;
    background-color: $gray-0;
    color: $gray-900;
    white-space: nowrap;
    z-index: 1000;
    visibility: hidden;
    display: block;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__icon {
    color: $business-500;

    &:hover {
      @include media-breakpoint-up(md) {
        color: $business-600;
      }
    }

    &--copied {
      @include media-breakpoint-up(md) {
        color: $business-700;
      }
    }
  }
}
