@import "../../styles/custom";

.our-mission-banner {
  padding: 72px 0;
  background-color: $gray-50;

  @include media-breakpoint-up(lg) {
    padding: 144px 0;
    background-color: $white;
  }

  &__badge {
    color: $gray-600;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    line-height: 150%;
    margin-bottom: 12px;

    @include media-breakpoint-up(lg) {
      font-size: 14px;
    }
  }

  &__title {
    font-size: 32px;
    font-weight: 600;
    line-height: 130%;
    margin-bottom: 16px;
    max-width: 744px;

    @include media-breakpoint-up(lg) {
      font-size: 36px;
    }
  }

  &__description {
    font-weight: 400;
    color: $gray-700;
    max-width: 744px;
    margin-bottom: 48px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 96px;
    }
  }

  &__benefits-wrapper {
    max-width: 100vw;
    --bs-gutter-x: 60px;
    --bs-gutter-y: 32px;

    @include media-breakpoint-up(md) {
      --bs-gutter-y: 64px;
    }

    @include media-breakpoint-up(lg) {
      --bs-gutter-y: 64px;
      --bs-gutter-x: 120px;
    }
  }

  &__benefit {
    h4 {
      display: flex;
      align-items: center;
    }

    p {
      padding-left: 48px;
    }
  }

  @at-root body.arabic {
    &__checkmark-wrapper {
      margin-left: 16px;
      margin-right: 0;
    }
  }

  &__checkmark-wrapper {
    background-color: $business-500;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    min-width: 32px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 16px;
  }
}

@at-root body.arabic {
  .our-mission-banner__checkmark-wrapper {
    margin-left: 16px;
    margin-right: 0;
  }
}
