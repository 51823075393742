@import "../../styles/custom";

.kc-link {
  font-weight: 500;
  text-decoration: none;

 

  &__link-icon{
    &__after{
      margin-inline-start: 8px;
    }
    &__before{
      margin-inline-end: 8px;
    }
  }
  &__label {
    font-weight: 500;
    text-decoration: none;
  }

  &--business {
    color: $business-500;

    &:hover {
      color: $business-600;
    }

    &:active {
      color: $business-800;
    }
  }

  &--individuals {
    color: $individuals-500;

    &:hover {
      color: $individuals-600;
    }

    &:active {
      color: $individuals-900;
    }
  }

  &--white {
    color: white;

    &:hover {
      color: $gray-200;
    }
  }

  &--danger {
    color: $danger-500;

    &:hover {
      color: $danger-600;
    }

    &:active {
      color: $danger-900;
    }
  }

  &--success {
    color: $success-900;

    &:hover {
      color: $success-600;
    }

    &:active {
      color: $success-900;
    }
  }

  &--info {
    color: $info-900;

    &:hover {
      color: $info-600;
    }

    &:active {
      color: $info-900;
    }
  }

  &--warning {
    color: $warning-900;

    &:hover {
      color: $warning-700;
    }

    &:active {
      color: $warning-900;
    }
  }

  &--black {
    color: $gray-900;

    &:hover {
      color: $gray-600;
    }

    &:active {
      color: $gray-900;
    }
  }

  &--text-link {
    position: relative;

    &::after {
      display: none;
    }

    &:hover,
    &:focus {
      .kc-link__label {
        text-decoration-thickness: 2px;
      }
    }

    &:focus {
      &:focus-visible {
        background-color: $warning-500;
        color: $gray-900;
        outline: none;
      }
    }
  }
  &--disabled{
    color: $gray-500 !important;
    cursor: not-allowed;
  }

  &--underlined {
    position: relative;

    .kc-link__label {
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-underline-offset: 4px;
    }
  }
  @at-root body.arabic {
    .kc-link--revert {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      .arrow-right-icon {
        position: relative;
        transform: rotate(180deg);
      }
    }
  }
}
