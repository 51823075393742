@import "../../styles/custom";

.accessibility-tools {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  align-items: center;
  justify-content: center;
  position: relative;

  @include media-breakpoint-up(md) {
    justify-content: space-between;
    gap: 12px;
  }

  &__active-tools-block {
    display: flex;
    align-items: center;
    gap: 16px;
    order: 2;

    @include media-breakpoint-up(md) {
      order: 1;
      gap: 12px;
    }
  }

  &__font-size-btn {
    padding: 0;
    background-color: transparent;
    border: none;
    color: $business-500;
    text-decoration: none;
    text-underline-offset: 2px;
    font-size: 14px;
    opacity: 1;
    @at-root body.arabic {
      .accessibility-tools__font-size-btn {
        direction: ltr;
      }
    }

    &:disabled {
      opacity: 0.5;
    }

    &:focus-visible {
      @include focus();
    }

    @include media-breakpoint-up(md) {
      text-decoration: underline;
    }
  }

  &__sign-language-link {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 16px;
    color: $business-500;
    border: 1px solid $gray-300;
    border-radius: 4px;
    order: 1;
    font-size: 14px;

    @include media-breakpoint-up(md) {
      order: 2;
    }
  }
}
