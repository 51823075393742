@import "../../../../../styles/custom";
.related-topics {
  width: 100%;
  padding: 96px 158px 96px 156px;
  background: $gray-100;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.related-topics--rtl {
    direction: rtl;

    .related-topics__icon {
      transform: scaleX(-1);
    }
  }

  @include media-breakpoint-down(lg) {
    padding: 32px 16px 48px 16px;
  }
  a {
    text-decoration: none;
  }

  &__heading {
    color: $gray-900;
    margin-bottom: 48px;
    font-size: 36px;
    font-weight: 600;
    line-height: 150%;
    word-wrap: break-word;
    @include media-breakpoint-down(md) {
      margin-bottom: 24px;
      font-size: 24px;
    }
  }

  &__list {
    max-width: 100%;
    display: flex;
    gap: 26px;
    padding: 3px 0;
    &__multiple{
      width: 100%;
      overflow-x: auto;
      max-width: 100%;
      display: flex;
      gap: 26px;
      padding: 3px 0;
    }
  }
  &__list::-webkit-scrollbar {
    width: 0;
  }

  &__actione-tile {
    flex: 0 0 262px;
    height: 210px;
    padding: 35px 24px 24px 24px;
    background: $gray-50;
    box-shadow: 0px 0px 0px $gray-900;
    border-radius: 4px;
    border-top: 1.5px $gray-900 solid;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &:focus-visible {
      @include focus();
    }
  }

  &__actione-tile-title {
    align-self: stretch;
    flex: 1 1 0;
    color: $gray-900;
    font-size: 20px;
    font-weight: 600;
    line-height: 140%;
    word-wrap: break-word;
  }

  &__actione-tile-container {
    border-radius: 90px;
    border: 1px $gray-100 solid;
    justify-content: center;
    align-items: center;
    display: inline-flex;
  }

  &__icon {
    width: 24px;
    height: 24px;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
  }
}
