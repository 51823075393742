@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/mixins";
@import "./_mixins.scss";

// custom bootstrap scss here

// Custom gray.
$gray-0: #ffffff;
$gray-50: #fafafa;
$gray-100: #f2f2f2;
$gray-200: #e6e6e7;
$gray-300: #c8c9cb;
$gray-500: #93979c;
$gray-600: #4b515a;
$gray-700: #2b313c;
$gray-800: #1b1f27;
$gray-900: #0a0e14;

// Custom color 'business'
$business-50: #fafdfe;
$business-100: #f0f7ff;
$business-300: #b7d3ec;
$business-500: #0060ff;
$business-600: #204d88;
$business-700: #173255;
$business-800: #0c2643;

// Custom color 'individuals'
$individuals-100: #f0f7ff;
$individuals-200: #cde2f4;
$individuals-400: #72aadc;
$individuals-500: #0060ff;
$individuals-600: #204d88;
$individuals-900: #173255;

// Custom color 'warning'
$warning-50: #fefaf4;
$warning-100: #fbf5e8;
$warning-300: #f8e1a4;
$warning-500: #f3c354;
$warning-600: #fa961e;
$warning-700: #ce7203;
$warning-900: #4c3705;

// Custom color 'info'
$info-50: #fafdff;
$info-100: #eef6fd;
$info-300: #b7d3ec;
$info-500: #2d71e0;
$info-600: #1e5ec7;
$info-900: #014ea7;

// Custom color 'danger'
$danger-50: #fef5f5;
$danger-100: #f8eaea;
$danger-300: #e8bdbd;
$danger-500: #b52b2b;
$danger-600: #9a3030;
$danger-900: #981c1c;

// Custom color 'success'
$success-50: #f1fdf7;
$success-100: #e5f1ec;
$success-300: #bde8d4;
$success-500: #2bb574;
$success-600: #1e925b;
$success-900: #1d794b;

// Custom Nitaqat colors
$nitaqat-red: #e9392c;
$nitaqat-low-green: #ddeaa0;
$nitaqat-mid-green: #b4e396;
$nitaqat-high-green: #8bae8b;
$nitaqat-platinum: #c3c3c3;

$shadow-light: 0px 7px 3px rgba(0, 0, 0, 0.01), 0px 2px 2px rgba(0, 0, 0, 0.06),
  0px 0px 1px rgba(0, 0, 0, 0.07), 0px 0px 0px rgba(0, 0, 0, 0.07);

$focus-outline: 0px 0px 0px 3px #f3c354, inset 0px 0px 0px 3px #000000;

@import "../../node_modules/bootstrap/scss/variables";

$container-max-widths: (
  xxs: 304px,
  xs: 342px,
  sm: 544px,
  md: 736px,
  lg: 960px,
  xl: 1127px,
  xxl: 1128px,
  xxxl: 1504px,
);

$grid-breakpoints: (
  xxxs: 0,
  xxs: 320px,
  xs: 375px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1176px,
  xxl: 1440px,
  xxxl: 1920px,
);
