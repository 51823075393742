@import "../../../styles/custom";

.tools-calculators-wrapper {
  width: 100%;
  background-color: $gray-50;

  &__border {
    width: 100%;
    border-top: 1px solid $gray-300;
  }
}

.tools-calculators-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 64px 156px;
  background-color: $gray-50;

  .tools-calculators-container__main-content {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    .tools-calculators-container__info_section {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      margin-inline-end: 20px;

      .tools-calculators-container__title {
        display: inline-block;
        max-width: 457px;
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 130%;
        letter-spacing: -0.5px;
        color: $gray-900;

        @include media-breakpoint-down(sm) {
          font-size: 36px;
        }
      }

      @include media-breakpoint-down(xl) {
        .title {
          max-width: 343px;
        }
      }

      .tools-calculators-container__info {
        display: inline-block;
        max-width: 457px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        margin-top: 16px;
        color: $gray-900;
      }

      @include media-breakpoint-down(xl) {
        margin-bottom: 48px;
        width: 100%;

        .info {
          width: auto;
        }
      }
    }

    .tools-calculators-container__service_section {
      height: 100%;

      .tools-calculators-container__card_container {
        max-width: 552px;
        margin-bottom: 32px;
      }
    }

    @include media-breakpoint-down(xl) {
      display: flex;
      flex-direction: column;
    }
  }

  @include media-breakpoint-down(xl) {
    max-width: media-breakpoint-down(xl);
    padding: 8px 16px;
  }
}

.certificate-calc-page {
  padding-top: 32px;
  background-color: $gray-50;

  @include media-breakpoint-up(lg) {
    padding-top: 64px;
  }
}
