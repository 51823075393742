@import "../../styles/custom";

.wp-calculator {
  &__top-inputs-wrapper {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    margin-bottom: 8px;
  }

  &__top-block {
    padding-bottom: 24px;
    border-bottom: 1px solid $gray-200;

    @include media-breakpoint-up(lg) {
      border-bottom: 2px solid $gray-200;
    }
  }

  &__top-control {
    width: 100%;
 
    @include media-breakpoint-up(lg) {
      width: calc(50% - 4px);

      &__saudi-error{
        margin-bottom: 37px;
      }
      &__non-saudi-error{
        margin-bottom: 37px;
      }
    }

    .form-label {
      font-size: 14px;
      font-weight: 300;
    }

    .form-control {
      height: 48px;
      border-radius: 4px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield; // Mozilla
    }
  }

  &__total-employee {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 0;

    span {
      font-weight: 400;
    }
  }

  &__mid-block {
    padding: 24px 0;
    border-bottom: 1px solid $gray-200;
  }

  &__radio-inputs-wrapper {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;

    @include media-breakpoint-up(lg) {
      gap: 8px;
    }

    label[data-component="RadioTile"] {
      @include media-breakpoint-up(lg) {
        width: calc(50% - 4px);
      }
    }
  }

  &__bottom-block {
    padding: 24px 0;

    div[data-component="Message"] {
      margin-top: 16px;
      margin-bottom: 16px;
      padding-bottom: 16px !important;
    }
  }

  &__range-input-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;

    &:last-of-type {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
      flex-wrap: nowrap;
      height: 64px;
      gap: 24px;
      margin-bottom: 8px;
    }
  }

  &__range-input-label {
    display: flex;
    flex-direction: column;
    gap: 4px;

    @include media-breakpoint-up(lg) {
      flex-basis: 66%;
    }

    h6 {
      font-size: 14px;
      font-weight: 500;
      margin: 0;
    }

    p {
      font-size: 12px;
      color: $gray-700;
      margin: 0;
      font-weight: 300;
    }
  }

  &__range-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    flex-basis: 100%;

    @include media-breakpoint-up(lg) {
      flex-basis: 28%;
      gap: 8px;
    }

    div[data-component="NumericField"] {
      @include media-breakpoint-up(lg) {
        width: 76px;
      }
    }

    input {
      width: 100%;
      height: 48px;
      padding: 12px 16px;
      text-align: center;
      border: 1px solid $gray-300 default;
      border-radius: 4px;
      -moz-appearance: textfield; // Mozilla

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &:focus {
        box-shadow: $focus-outline;
        outline: none;
      }

      @include media-breakpoint-up(lg) {
        width: 76px;
      }
    }
  }

  &__range-button {
    border: none;
    background-color: white;
    color: $business-500;
    padding: 0;

    &:disabled {
      opacity: 0.5;
    }
  }

  &__block_title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
  }

  &__block_description {
    font-size: 14px;
    margin-bottom: 16px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 8px;
    }
  }

  &__output-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 0;
    border-bottom: 1px solid $gray-200;
    font-size: 16px;
  }

  &__output-row-label {
    font-weight: 300;
    max-width: 66%;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 4px;

    span {
      display: inline;
      font-size: 14px;
      font-weight: 300;

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  &__output-row-value {
    font-weight: 600;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 16%;
    text-align: right;

    @at-root body.arabic {
      .wp-calculator__output-row-value {
        text-align: left;
      }
    }

    p {
      margin: 0;
    }
  }

  &__output-row-formula {
    font-size: 14px;
    font-weight: 300;
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  &__total-result-row {
    padding-top: 24px;
    margin-bottom: 24px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }

    p {
      margin: 0;
    }
  }

  &__total-result {
    font-size: 32px;
    font-weight: 600;
    line-height: 130%;
  }
}

.wp-calc-container {
  padding-bottom: 24px;

  @include media-breakpoint-up(lg) {
    padding-bottom: 48px;
  }

  &__note {
    border-top: 1px solid $gray-200;
    padding: 48px 0 40px;
  }

  &__note-list {
    padding: 0;
    list-style: disc !important;
    p {
      font-weight: 400;
      margin-bottom: 8px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 1rem;
      }
    }

    &__header{
      font-weight: 600;
      @include media-breakpoint-down(lg) {
        display: block;
        margin-bottom: 8px;
        font-size: 24px;
      }
    }
    span {
      font-weight: 600;
    }
    li {
      margin-inline-start: 25px;
      font-weight: 400;
    }
  }

  &__note-footer {
    margin-top: 16px;
    font-weight: 400;
  }

  button[type="submit"] {
    width: 100%;

    @include media-breakpoint-up(lg) {
      width: auto;
    }
  }

  .certificate-validation-tool__result-item {
    padding-top: 0;
  }
}
