@import "../../styles/custom";

.feedback {
  width: 100%;
  border-top: 1px solid $gray-300;
  background-color: $gray-50;
  &_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 128px;
    background-color: $gray-50;
    padding: 40px 156px;

    &--rtl {
      .feedback_container__actions {
        margin-right: 24px;
      }

      .feedback_container__button {
        margin-left: 12px;

        @include media-breakpoint-down(lg) {
          margin-left: 4px;
        }
      }
    }

    &--ltr {
      .feedback_container__actions {
        margin-left: 24px;
      }

      .feedback_container__button {
        @include media-breakpoint-up(lg) {
          margin-right: 12px;
        }

        @include media-breakpoint-down(lg) {
          .feedback_container__actions:first-child {
            margin-right: 4px;
          }
        }
      }
    }

    @include media-breakpoint-down(lg) {
      height: 224px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 32px 24px;
    }

    &__page-useful {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include media-breakpoint-down(lg) {
        min-width: 334px;
        flex-direction: column;
        margin-bottom: 24px;
      }

      .feedback_container__text {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: $gray-900;
        margin: 0 auto;

        @include media-breakpoint-down(lg) {
          margin: 0 0 16px 0;
          width: 100%;
        }
      }

      .feedback_container__actions {
        @include media-breakpoint-down(lg) {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin: 0;
        }
      }
    }

    &__button {
      padding: 11px 24px;
      background-color: $white;
      color: $business-500;
      border: 1px solid $gray-300;
      border-radius: 4px;

      @include media-breakpoint-down(lg) {
        padding: 11px 67px 13px;
        min-width: 162px;
      }

      &:hover {
        background-color: $business-600;
        color: $white;
      }
    }

    .feedback_container__report {
      width: 181px;
      white-space: nowrap;
      &-button {
        @include media-breakpoint-down(lg) {
          padding: 11px 97px 13px;
          min-width: 328px;
        }
      }

      @include media-breakpoint-down(lg) {
        min-width: 334px;
      }

      a {
        display: inline-block;
        width: 100%;
        text-align: center;
      }
    }

    &__button:focus {
      &:focus-visible {
        background-color: $warning-500;
        color: $gray-900;
        border: 3px solid $gray-900;
      }
    }
  }
}
