@import "../../../styles/custom";

.homepage-tabs-list {
  position: relative;
  margin-top: -56px;
  z-index: 3;

  @include media-breakpoint-up(lg) {
    margin-top: -57px;
  }

  @include media-breakpoint-down(xl) {
    padding-inline-start: 16px;
  }

  .homepage-tabs {
    &__tabs-list {
      flex-wrap: nowrap;
      max-width: 100%;
      overflow-x: scroll;
      overflow-y: hidden;
      -ms-overflow-style: none;
      scrollbar-width: none;
      padding: 3px;

      &::-webkit-scrollbar {
        display: none;
      }

      .nav-item {
        .nav-link {
          text-decoration: none;
          color: $white;
          white-space: nowrap;
          font-size: 14px;
          padding: 1rem;
          font-weight: 600;
          border-bottom: 0;
          border-radius: 4px 4px 0px 0px;

          @include media-breakpoint-up(lg) {
            font-size: 1rem;
            padding: 1rem 1.5rem;
          }

          &:focus {
            &:not(:focus-visible) {
              box-shadow: none;
            }

            &:focus-visible {
              @include focus();
            }
          }

          &.active {
            color: $gray-900;
            font-weight: 600;
            background-color: $gray-50;
            margin-bottom: 0;
          }
        }
      }
    }

    &__next-button,
    &__prev-button {
      position: absolute;
      top: 50%;
      transform: translateY(calc(-50% - 2px));
      width: 56px;
      height: 56px;
      border: 0;
      padding: 0;
      background-color: $business-800;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    &__next-button {
      inset-inline-end: 0;
    }

    &__prev-button {
      inset-inline-start: 0;
    }
  }
}

.homepage-tab-pane {
  .action-tiles-group {
    &:last-child {
      .accordion-item {
        .accordion-button {
          &::before {
            display: none;
          }
        }
      }
    }
  }
}

.tools-block {
  padding: 0;
  position: relative;
  background-color: $gray-50;
  border-top: 1px solid $gray-300;
  .action-tiles-group {
    @include media-breakpoint-down(md) {
      border-top: 1px solid $gray-300;
      padding-top: 40px;
      padding-bottom: 64px;
    }
  }
  .action-tiles-group__group-cta{
    @include media-breakpoint-down(md) {
      padding-bottom: 0px;
    }
  }

  @include media-breakpoint-up(md) {
    padding: 32px 0 48px;
  }

  @include media-breakpoint-up(lg) {
    padding: 36px 0 84px;
  }
}

.business-tab,
.individuals-tab,
.providers-tab {
  background-color: $gray-50;
  padding: 8px 0 40px;
  width: 100%;

  @include media-breakpoint-up(md) {
    padding: 40px 0 16px;
  }
  @include media-breakpoint-up(lg) {
    padding: 36px 0 44px;
  }
}

.bottom-tab-divider-wrapper {
  position: relative;
  height: 9px;
  background-color: $gray-50;
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
  }

  .container {
    position: relative;

    &::after {
      content: "";
      display: block;
      height: 1px;
      width: 100%;
      max-width: inherit;
      background-color: $gray-300;
      position: absolute;
      top: 0;
      inset-inline-start: 0;
    }
  }
}
.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 460px;
}
