@import "../../styles/custom";

.article-cards-group {
  & &__wrapper {
    padding-top: 56px;
    padding-bottom: 64px;
    background-color: $business-50;

    @include media-breakpoint-up(md) {
      padding-top: 40px;
      padding-bottom: 64px;
      background-color: $gray-50;
    }

    @include media-breakpoint-up(lg) {
      padding-top: 72px;
      padding-bottom: 120px;
      background-color: $white;
    }
  }

  & &__title-col {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  & &__title {
    font-size: 32px;
    font-weight: 600;
    line-height: 130%;
    margin-bottom: 0;

    @include media-breakpoint-up(lg) {
      font-size: 36px;
    }
  }

  & &__description {
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 32px;
    margin-top: 8px;

    @include media-breakpoint-up(md) {
      margin-top: 24px;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 64px;
      font-size: 20px;
      max-width: 83.33%;
    }
  }

  & &__cards-row {
    @include media-breakpoint-only(md) {
      --bs-gutter-x: 1.5rem;
    }
  }

  & &__twitter-col {
    .article-card {
      border: none;
      box-shadow: $shadow-light;

      .card-body {
        padding: 0;
      }
    }
  }

  & &__all-articles-link {
    &.kc-link {
      width: auto;

      @include media-breakpoint-up(md) {
        margin-inline-end: 26px;
        text-align: right;
      }

      &__label {
        &::after {
          height: 2px;
        }
      }

      .icon {
        position: absolute;
        margin-inline-start: 2px;
        color: $business-500;
      }
    }
  }

  &--small-cards {
    background-color: $gray-50;
    padding-bottom: 0;
    border-bottom: 1px solid $gray-200;

    .article-cards-group__wrapper {
      padding-top: 48px;
      background-color: $gray-50;
      padding-bottom: 33px;

      @include media-breakpoint-up(lg) {
        padding-top: 120px;
        background-color: $gray-50;
        padding-bottom: 64px;
      }
    }

    .article-cards-group__title-col {
      margin-bottom: 40px;

      @include media-breakpoint-up(lg) {
        flex-wrap: nowrap;
      }

      .article-cards-group__title {
        margin-bottom: 0;
      }
    }

    .article-cards-group__all-articles-link {
      margin-inline-end: -6px;
      margin-top: 64px;
      width: auto;
      padding: 0;

      .icon {
        position: absolute;
        color: $business-500;
      }

      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }

    .article-cards-group__cards-row {
      align-items: stretch;

      .carousel {
        box-shadow: $shadow-light;
        padding: 0;
        border-radius: 4px;
      }

      .carousel-indicators {
        bottom: -64px;

        button {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          opacity: 1;
          background-color: $gray-300;

          &.active {
            background-color: $business-500;
          }
        }
      }

      .article-card-wrapper {
        height: 100%;

        .article-card {
          height: 100%;
          min-height: 524px;

          .card-img-top {
            height: 208px;
          }

          .card-body {
            display: flex;
            flex-direction: column;
            padding: 24px 24px 32px;

            .card-title {
              height: 68px;
            }

            .card-link {
              margin-top: auto;
            }
          }
        }
      }
    }
  }
}
