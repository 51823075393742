@import "../../styles/custom";

.nitaqat-calculator {
  background-color: $gray-50;
  padding-bottom: 48px;
  position: relative;

  &__divider {
    border-top: 1px solid $gray-300;
    margin-top: 120px;
    margin-bottom: 56px;
  }

  &--rtl {
    .nitaqat-calculator__description__text {
      margin-right: 10px;
    }

    .nitaqat-calculator__result__container__centered {
      margin-left: 16px;
      margin-right: unset;
    }

    .nitaqat-calculator__calc-content__flex {
      .nitaqat-calculator__calc-content__form:first-child {
        margin-left: 8px;
        margin-right: unset;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 120px;
  }

  &__content {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      flex-wrap: nowrap;
      width: auto;
    }
  }

  &__text-content {
    width: calc(100% - 32px);

    @include media-breakpoint-up(lg) {
      max-width: 50%;
      width: 100%;
    }
  }

  &__result {
    &__title {
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
      letter-spacing: -0.5px;
      color: $gray-900;
      text-align: center;
      margin-bottom: 40px;

      @include media-breakpoint-down(lg) {
        text-align: left;
      }
    }

    &__container {
      display: flex;
      justify-content: center;

      @include media-breakpoint-down(lg) {
        flex-direction: column;
        width: 100vw;
        margin-inline-start: -16px;
      }

      .width-50 {
        width: 50%;
      }

      &__centered {
        margin: 0 auto;
        width: 100%;

        @include media-breakpoint-up(lg) {
          width: 50%;
        }
      }

      &__centered.mr-2 {
        margin-right: 16px !important;
      }

      &__info--box {
        width: 100%;
        height: 160px;

        &__title {
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%; /* 24px */
        }
      }
    }
  }

  &__return-btn {
    background-color: white;
    border: none;
    display: flex;
    align-items: center;
    color: $business-500;

    &--top {
      margin-inline-start: -15px;
      margin-bottom: 8px;
      display: none;

      @include media-breakpoint-up(lg) {
        display: flex;
      }
    }

    @at-root body.arabic {
      .certificate-validation-tool__return-btn--top {
        .icon {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__result-list {
    list-style: none;
    margin: 0;
    padding: 0;
    border-top: 1px solid $gray-200;
  }

  &__result-item {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    align-items: center;
    padding: 24px 0;
    border-bottom: 1px solid $gray-200;

    &:last-of-type {
      border-bottom: none;

      .nitaqat-calculator__result-item-value {
        font-size: 32px;
      }
    }

    .certificate-validation-tool__result-item-label,
    .certificate-validation-tool__result-item-value {
      margin: 0;
      font-size: 16px;
    }

    &:last-child {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__result-item-label {
    font-weight: 400;
    margin: auto 0;
  }

  &__result-item-value {
    font-weight: 600;
    text-align: right;
    margin: auto 0;

    span {
      font-weight: 600;
    }
  }

  &__title {
    font-size: 48px;
    font-weight: 700;
    letter-spacing: -0.5px;
    margin: 0;
    max-width: 457px;
  }

  &__description {
    font-size: 16px;
    font-weight: 400;
    margin: 16px 0 0;
    max-width: 457px;
    line-height: 150%;

    &__text {
      margin-inline-end: 10px;
    }
  }

  &__description-small {
    font-size: 14px;
    line-height: 150%;
    span {
      font-weight: 500;
    }
    a{
      font-size: 16px;
    }
  }

  &__description :first-child {
    margin-bottom: 36px;
  }

  &__validation-result {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
  }

  &__cert-status {
    text-transform: uppercase;
    font-weight: 16px;
    margin: 0;
    padding: 0 4px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    color: white;

    &--expired {
      background-color: $danger-500;
    }

    &--active {
      background-color: $success-900;
    }
  }

  &__error-msg {
    color: $nitaqat-red;
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    inset-inline-start: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.5;
    z-index: 10;
  }
}
