@import "../../styles/custom";

.pagination-pager {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
  margin-top: 32px;

  @include media-breakpoint-up(lg) {
    margin-top: 36px;
    flex-direction: row;
  }

  &__items-select-wrapper {
    display: none;
    align-items: center;
    min-width: 210px;
    gap: 24px;

    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }

  &__items-per-page-select {
    padding: 12px 16px;
    width: 95px;
    background-position: right 0.75rem center;

    @at-root body.arabic {
      .pagination-pager__items-per-page-select {
        background-position: left 0.75rem center;
      }
    }

    &:focus-visible {
      @include focus();
    }
  }

  &__pagination {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 6px;
    position: relative;
    inset-inline-end: -8px;
  }

  &__page-item {
    padding: 8px 10px;
    border-bottom: 3px solid transparent;

    &:last-of-type {
      padding-inline-end: 0;
    }

    &:focus-within {
      background-color: $warning-500;
      border-bottom: 3px solid $gray-900;
      a{
        color: $gray-900;
      }
    }

    &.disabled {
      display: none;
    }
  }

  &__page-link {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 4px;
    color: $business-500;
    font-weight: 500;
    font-size: 14px;
    white-space: nowrap;

    &:focus-visible {
      background-color: $warning-500;
      color: $gray-900;
      outline: none;
      text-decoration: none;
    }
  }

  &__active-page {
    background-color: $business-500;
    border-radius: 4px;

    a {
      color: white;
      font-weight: 600;
      text-decoration: none;
    }
  }

  &__info-wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      gap: 32px;
    }
  }
}
