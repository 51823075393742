@import "../../../styles/custom";

.sla-page {
  background-color: $gray-50;

  &__hero {
    background-color: $business-800;
    padding: 64px 0 80px;
  }

  &__title {
    font-size: 48px;
    font-weight: 700;
    letter-spacing: -0.5px;
    color: white;
  }

  &__content {
    padding: 0 0 120px;

    .tab-content {
      padding-top: 56px;

      @include media-breakpoint-up(lg) {
        padding-top: 64px;
      }
    }

    .sla-page-tabs-list {
      position: relative;
      width: 100%;
      max-width: 100% !important;
    }
  }

  .breadcrumbs-kc {
    .icon {
      color: white;
    }
  }

  .sla-page-tabs {
    &__tabs-list {
      margin-top: -56px;
      flex-wrap: nowrap;
      max-width: 100%;
      overflow-x: scroll;
      overflow-y: hidden;
      -ms-overflow-style: none;
      scrollbar-width: none;
      display: flex;

      &::-webkit-scrollbar {
        display: none;
      }

      .nav-item {
        padding: 16px 24px;
        border-radius: 4px 4px 0 0;

        .nav-link {
          padding: 0;
          color: white;
          font-weight: 600;
          white-space: nowrap;

          &.active {
            color: $gray-900;
          }
        }

        &--active {
          background-color: $gray-50;
        }
      }
    }

    &__next-button,
    &__prev-button {
      height: 53px;
      background-color: $business-800;
      border: none;
      color: white;
    }

    &__next-button {
      position: absolute;
      inset-inline-end: -16px;
      top: 0;

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    &__prev-button {
      position: absolute;
      inset-inline-start: -16px;
      top: 0;

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  .sla-table {
    &__list {
      list-style: none;
      padding: 40px 48px;
      border: 1px solid $gray-200;
      background-color: white;
      border-radius: 8px;
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    &__accordion-list {
      display: block;

      @include media-breakpoint-up(lg) {
        display: none;
      }

      .accordion {
        background-color: $gray-50;
        flex-direction: column;
        gap: 8px;
        border: none;

        .accordion-item {
          padding: 12px 0;
          border-radius: 4px;
          border: 1px solid $gray-200;
          box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.06);
          width: 100%;
        }

        .accordion-button {
          padding: 16px 12px;
          border-radius: 0;
          font-weight: 600;
          font-size: 16px;
          justify-content: space-between;
          --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.89348 8.59367C5.50295 8.9842 5.50295 9.61736 5.89348 10.0079L11.293 15.4074C11.6835 15.7979 12.3167 15.7979 12.7072 15.4074L18.1067 10.0079C18.4972 9.61736 18.4972 8.9842 18.1067 8.59367C17.7162 8.20315 17.083 8.20315 16.6925 8.59367L12.0001 13.2861L7.30769 8.59367C6.91717 8.20315 6.284 8.20315 5.89348 8.59367Z' fill='%230E171B'/%3E%3C/svg%3E%0A");
          --bs-accordion-btn-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.89348 8.59367C5.50295 8.9842 5.50295 9.61736 5.89348 10.0079L11.293 15.4074C11.6835 15.7979 12.3167 15.7979 12.7072 15.4074L18.1067 10.0079C18.4972 9.61736 18.4972 8.9842 18.1067 8.59367C17.7162 8.20315 17.083 8.20315 16.6925 8.59367L12.0001 13.2861L7.30769 8.59367C6.91717 8.20315 6.284 8.20315 5.89348 8.59367Z' fill='%230E171B'/%3E%3C/svg%3E%0A");
          --bs-accordion-btn-icon-width: 24px;
          box-shadow: none;

          &::after {
            margin: 0;
          }

          &:not(.collapsed) {
            background-color: white;
            color: inherit;
          }
        }

        .accordion-body {
          padding: 8px 12px;
          display: flex;
          flex-direction: column;
          gap: 12px;
        }
      }
    }

    &__accordion-action {
      margin-top: 8px;
      width: 100%;
      background-color: $business-500;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px 24px;
      border-radius: 4px;
      color: white;

      &--disabled {
        margin-top: 8px;
        width: 100%;
        background-color: $gray-500;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 24px;
        border-radius: 4px;
        color: white;
        cursor: not-allowed;
        background-color: $gray-100;
      }
      &:hover {
        background-color: $business-700;
      }
    }

    &__accordion-body-item-label {
      font-size: 14px;
      font-weight: 400;
      margin: 0;
      margin-bottom: 4px;
    }

    &__accordion-body-item-value {
      font-size: 14px;
      font-weight: 600;
      margin: 0;
    }

    &__list-item {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      font-size: 14px;

      @include media-breakpoint-up(lg) {
        flex-wrap: nowrap;
      }

      &--header {
        background-color: $gray-50;

        div {
          font-weight: 600;
        }
      }

      background-color: white;
    }

    &__list-item-column {
      padding: 14px 16px;
    }

    &__list-item-service {
      flex-basis: 17%;
      font-weight: 600;
    }

    &__list-item-time {
      flex-basis: 16%;
    }

    &__list-item-availability {
      flex-basis: 16%;
    }

    &__list-item-customer-care {
      flex-basis: 17%;
    }

    &__list-item-complain-resolution {
      flex-basis: 17%;
    }

    &__list-item-action {
      flex-basis: 17%;
    }
  }

  .sla-filters {
    margin-bottom: 32px;

    &__desktop-wrapper {
      display: none;

      @include media-breakpoint-up(lg) {
        display: flex;
        flex-wrap: wrap;
        gap: 8px 4px;
      }
    }

    &__mobile-wrapper {
      display: block;

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    &__open-filters {
      background-color: white;
      width: 100%;
      padding: 11px 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $gray-300;
      border-radius: 4px;
      color: $business-500;
    }

    &__filter {
      font-size: 14px;
      padding: 8px 16px;
      border: 1px solid $gray-300;
      border-radius: 99px;
      white-space: nowrap;
      background-color: white;

      &--active {
        background-color: $business-700;
        color: white;
      }
    }

    &__mobile-popup {
      position: absolute;
      top: 61px;
      background-color: $gray-50;
      width: 100vw;
      height: var(--window-inner-height);
      overflow-y: auto;
      inset-inline-start: 0;
      padding: 16px 0 0 0;
      z-index: 3;

      fieldset {
        padding: 16px 32px;
        border-bottom: 1px solid $gray-200;

        label {
          font-size: 16px;
          margin-inline-start: 16px;
        }

        input {
          min-height: 20px;
          appearance: none;
          position: relative;

          &:checked {
            &::after {
              border-color: $business-500;
              border-width: 6px;
              transition: all 0.15s ease-in-out;
            }
          }

          &::after {
            position: absolute;
            top: 50%;
            transform: translateY(calc(-50% + 4px));
            inset-inline-start: -16px;
            content: "";
            display: block;
            height: 20px;
            width: 20px;
            background-color: white;
            border: 1px solid $gray-600;
            border-radius: 50%;
            z-index: 100;
            transition: all 0.15s ease-in-out;
          }
        }
      }
    }

    &__back-button {
      background-color: $gray-50;
      border: none;
      color: $business-500;
    }

    &__clear-filters-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px;

      h6 {
        margin: 0;
        font-size: 24px;
      }
    }

    &__clear-filters {
      background-color: $gray-50;
      border: none;
      color: $business-500;
      text-decoration: underline;
    }

    &__apply-filters-btn-wrapper {
      position: relative;
      z-index: 100;
      bottom: 0;
      inset-inline-start: 0;
      padding: 16px 16px 32px 16px;
      width: 100%;
      border-top: 1px solid $gray-200;
      background-color: white;
    }

    &__apply-filters-btn {
      width: 100%;
      border-radius: 4px;
      border: none;
      background-color: $business-500;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px 24px;
      color: white;

      &:hover,
      &:active {
        background-color: $business-700;
      }
    }
  }

  &__tab-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 56px;

    @include media-breakpoint-up(lg) {
      font-size: 32px;
    }
  }

  .sla-table {
    &__filters {
      &--mobile {
        display: block;

        @include media-breakpoint-up(lg) {
          display: none;
        }
      }

      &--desktop {
        display: none;

        @include media-breakpoint-up(lg) {
          display: flex;
        }
      }
    }
  }
}
